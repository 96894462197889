import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-insufficient-permissions',
  templateUrl: './insufficient-permissions.component.html',
  styleUrls: ['./insufficient-permissions.component.scss'],
})
export class InsufficientPermissionsComponent implements OnInit {
  constructor() {}

  get message(): string {
    return 'You do not have access to this part of the system';
  }

  ngOnInit(): void {}
}
