import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Auth, Hub } from 'aws-amplify';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

export interface AuthState {
  isLoggedIn: boolean;
  username: string | null;
  id: string | null;
  email: string | null;
}

const initialAuthState = {
  isLoggedIn: false,
  username: null,
  id: null,
  email: null,
};

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly _authState = new BehaviorSubject<AuthState>(
    initialAuthState
  );

  hideMenus = true;

  private _groups: string[] = [];

  public get groups(): string[] {
    return this._groups;
  }

  public set groups(value: string[]) {
    this._groups = value;
  }

  /** AuthState as an Observable */
  readonly auth$ = this._authState.asObservable();

  /** Observe the isLoggedIn slice of the auth state */
  readonly isLoggedIn$ = this.auth$.pipe(map((state) => state.isLoggedIn));

  constructor(private router: Router, private zone: NgZone,) {
    // Get the user on creation of this service
    Auth.currentAuthenticatedUser().then(
      (user: any) => this.setUser(user),
      (_err) => this._authState.next(initialAuthState)
    );
    // Use Hub channel 'auth' to get notified on changes
    Hub.listen('auth', ({ payload: { event, data, message } }) => {
      if (event === 'signIn' || event === 'tokenRefresh') {
        // On 'signIn' event, the data is a CognitoUser object
        this.setUser(data);
        if(event === 'signIn')
        window.location.reload();
       // this.zone.run(() => this.router.navigate(['/locations']));
      } else {
        this._authState.next(initialAuthState);
      }
    });
  }

  isLoggedIn = () => {
    return !!this.isLoggedIn$;
  };

  public signOut(): void {
    Auth.signOut()
      .then(() => {
        this.router.navigate(['/auth']), (this.hideMenus = true);
      })
      .catch((err) => console.log('signOut: ', err));
  }

  private setUser(user: any) {
    if (!user) {
      this.hideMenus = true;
      return;
    }

    this.hideMenus = false;
    this.groups = user.signInUserSession.accessToken.payload['cognito:groups'];

    const {
      attributes: { sub: id, email },
      username,
    } = user;

    this._authState.next({ isLoggedIn: true, id, username, email });
  }

  public hasGroup(groupName: string): boolean {
    return this.groups?.includes(groupName);
  }
}
