import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss'],
})
export class UserDetailsComponent implements OnInit {
  @Input() title: string = '';
  @Input() data: any;
  groups: any[];

  panelOpenState = true;

  webbookingGroups: string[] = [
    'containerapiadmin',
    'containerapiuser',
    'geodataadmin',
    'geodatauser',
    'cognitoadmin',
  ];
  groupsToAdd: string[] = [];
  groupnames: string[] = [];

  displayedColumns: string[] = ['LastModifiedDate', 'GroupName', 'actions'];
  constructor(
    public bsModalRef: BsModalRef,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.getUserGroups();
  }

  getUserGroups(): void {
    this.userService.getUserGroups(this.data.Username).subscribe((x) => {
      this.groups = x.Groups;
      this.groupnames = x.Groups.map((x: any) => x.GroupName);
      this.groupsToAdd = this.webbookingGroups.filter(
        (element) => !this.groupnames.includes(element)
      );
    });
  }

  deleteUserGroup(element: any): void {
    this.userService
      .deleteUserGroup(this.data.Username, element.GroupName)
      .subscribe((x) => this.getUserGroups());
  }

  addUserGroup(action: string): void {
    this.userService
      .addUserGroup(this.data.Username, action)
      .subscribe((x) => this.getUserGroups());
  }
}
