import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { runtimeEnvironment } from 'src/environments/environment.docker';
import { ContainerInterface } from '../models/container.interface';
import { PaginationInterface } from '../models/pagination.interface';

@Injectable({
  providedIn: 'root',
})
export class ContainerService {
  endpoint = runtimeEnvironment.geodataUrl;
  containerUrl = runtimeEnvironment.containerUrl;
  adminAddressPart = '/v1/admin/addresses/';
  constructor(private http: HttpClient) {}

  removeContainer(containerId: string): Observable<void> {
    return this.http.delete<void>(
      `${this.containerUrl}/Container/${containerId}`
    );
  }

  createContainer(containerNo: string, body: any): Observable<void> {
    return this.http.put<void>(
      `${this.containerUrl}/Container/${containerNo}`,
      body
    );
  }

  getContainers(
    queryString: string
  ): Observable<PaginationInterface<ContainerInterface[]>> {
    return this.http.get<PaginationInterface<ContainerInterface[]>>(
      `${this.containerUrl}/Container${queryString}`
    );
  }

  getContainer(containerNo: string): Observable<any> {
    return this.http.get<ContainerInterface>(
      `${this.containerUrl}/Container/${containerNo}`
    );
  }
}
