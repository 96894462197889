<div class="row">
  <div class="col-12">
    <div class="row">
      <div class="col-12">
        <div class="header d-flex align-items-center justify-content-between">
          <h3 class="mb-0 col-4">Locations</h3>
          <div class="col-sm-6 col-md-4 col-xl-4 text-end">
            <button class="col-sm-12 col-md-4 col-xl-5 btn btn-ancotrans mb-2" (click)="downloadAuditLog()">
              Download audit log
            </button>
            <button class="col-sm-12 col-md-4 col-xl-5 btn btn-ancotrans mb-2" (click)="onCreate()">
              Create location
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="top-spacer"></div>
    <div>
      <form [formGroup]="searchForm" class="sticky-header">
        <div class="row">
          <div class="col-md-3 col-sm-6 d-flex">
            <mat-form-field class="w-100" appearance="outline">
              <mat-label>Search name</mat-label>
              <input
                autocomplete="off"
                matInput
                formControlName="searchName"
                (ngModelChange)="resetOffset()"
              />
              <mat-icon matSuffix>search</mat-icon>
            </mat-form-field>
          </div>
          <div class="col-md-3 col-sm-6 d-flex">
            <mat-form-field class="w-100" appearance="outline">
              <mat-label>Search street</mat-label>
              <input
                autocomplete="off"
                matInput
                formControlName="searchStreet"
                (ngModelChange)="resetOffset()"
              />
              <mat-icon matSuffix>search</mat-icon>
            </mat-form-field>
          </div>

          <div class="col-md-3 col-sm-6 d-flex">
            <mat-form-field class="w-100" appearance="outline">
              <mat-label>Search house number</mat-label>
              <input
                autocomplete="off"
                matInput
                formControlName="searchHouseNumber"
                (ngModelChange)="resetOffset()"
              />
              <mat-icon matSuffix>search</mat-icon>
            </mat-form-field>
          </div>

          <div class="col-md-3 col-sm-6 d-flex">
            <mat-form-field class="w-100" appearance="outline">
              <mat-label>Search zip</mat-label>
              <input
                matInput
                autocomplete="off"
                formControlName="searchZip"
                (ngModelChange)="resetOffset()"
              />
              <mat-icon matSuffix>search</mat-icon>
            </mat-form-field>
          </div>
          <div class="col-md-3 col-sm-6 d-flex">
            <mat-form-field class="w-100" appearance="outline">
              <mat-label>Search city</mat-label>
              <input
                matInput
                autocomplete="off"
                formControlName="searchCity"
                (ngModelChange)="resetOffset()"
              />
              <mat-icon matSuffix>search</mat-icon>
            </mat-form-field>
          </div>
          <div class="col-md-3 col-sm-6 d-flex">
            <mat-form-field class="w-100" appearance="outline">
              <mat-label>Select country code</mat-label>
              <mat-select
                (change)="resetOffset()"
                formControlName="searchCountryCode"
              >
                <mat-option
                  *ngFor="let country of countries"
                  [value]="country.code"
                >
                  {{ country.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-md-3 col-sm-6 d-flex">
            <mat-form-field class="w-100" appearance="outline">
              <mat-label>Select result type</mat-label>
              <mat-select
                (change)="resetOffset()"
                formControlName="resultType"
                [ngClass]="{
                  'validator-error':
                    searchForm.controls['resultType'].invalid &&
                    searchForm.controls['resultType'].touched
                }"
              >
                <mat-option
                  *ngFor="let searchType of searchTypes"
                  [value]="searchType.value"
                >
                  {{ searchType.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-3 col-sm-6 d-flex">
            <mat-form-field class="w-100" appearance="outline">
              <mat-label>Select location type</mat-label>
              <mat-select
                (change)="resetOffset()"
                formControlName="searchAddressType"
              >
                <mat-option
                  *ngFor="let locationType of locationTypes"
                  [value]="locationType.type"
                >
                  {{ locationType.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-md-3 col-sm-6 d-flex">
            <mat-form-field
              appearance="outline"
              class="datepicker-container w-100"
            >
              <mat-label>Choose from date</mat-label>
              <input
                autocomplete="off"
                matInput
                [matDatepicker]="pickerFrom"
                formControlName="searchStartDate"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="pickerFrom"
              ></mat-datepicker-toggle>
              <mat-datepicker #pickerFrom></mat-datepicker>
            </mat-form-field>
          </div>

          <div class="col-md-3 col-sm-6 d-flex">
            <mat-form-field
              appearance="outline"
              class="datepicker-container w-100"
            >
              <mat-label>Choose to date</mat-label>
              <input
                autocomplete="off"
                matInput
                [matDatepicker]="pickerTo"
                formControlName="searchEndDate"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="pickerTo"
              ></mat-datepicker-toggle>
              <mat-datepicker #pickerTo></mat-datepicker>
            </mat-form-field>
          </div>

          <div class="col-md-3 col-sm-6 d-flex">
            <mat-checkbox
              [checked]="forceName"
              (change)="forceName = !forceName"
            >
              <span style="white-space: break-spaces">
                Show forced names only</span
              ></mat-checkbox
            >
          </div>

          <div class="col-md-3 col-sm-6 d-flex">
            <mat-checkbox
              data-toggle="tooltip"
              title="Filtering available on the non-validated addresses tab"
              [disabled]="tabIndexSelected == 0"
              [checked]="isCandidate"
              (change)="isCandidate = !isCandidate"
            >
              <span style="white-space: break-spaces"
                >Show only candidate addresses</span
              >
            </mat-checkbox>
          </div>
        </div>
        <div class="row p-2">
          <div class="col-12">
            <div class="row">
              <button
                (click)="getLocations()"
                class="col-sm-12 col-md-1 col-xl-1 btn btn-ancotrans mb-2"
              >
                Search
              </button>
              <button
                (click)="clearForm()"
                class="col-sm-12 col-md-1 col-xl-1 btn btn-ancotrans mb-2"
              >
                Clear
              </button>
            </div>
          </div>
        </div>
      </form>

      <mat-tab-group
        (selectedTabChange)="tabChanged($event)"
        [(selectedIndex)]="tabIndexSelected"
        class="mt-3"
      >
        <mat-tab label="Addresses">
          <ng-container *ngTemplateOutlet="table"></ng-container>
        </mat-tab>
        <mat-tab label="Unvalidated addresses">
          <ng-container *ngTemplateOutlet="table"></ng-container>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>

<ng-template #table>
  <table class="table table-striped">
    <thead>
      <tr>
        <th
          *ngIf="tabIndexSelected == 1"
          class="expand-column"
          scope="col"
        ></th>
        <th scope="col" class="pointer" (click)="sortLocations('type')">
          Type
          <i
            *ngIf="sortValues.type"
            class="fa"
            [ngClass]="{
              'fa-arrow-down': sortValues.type == 'asc',
              'fa-arrow-up': sortValues.type == 'desc'
            }"
          ></i>
        </th>
        <th scope="col" class="pointer" (click)="sortLocations('address')">
          Address
          <i
            *ngIf="sortValues.address"
            class="fa"
            [ngClass]="{
              'fa-arrow-down': sortValues.address == 'asc',
              'fa-arrow-up': sortValues.address == 'desc'
            }"
          ></i>
        </th>
        <th scope="col" class="pointer" (click)="sortLocations('houseNumber')">
          House number
          <i
            *ngIf="sortValues.houseNumber"
            class="fa"
            [ngClass]="{
              'fa-arrow-down': sortValues.houseNumber == 'asc',
              'fa-arrow-up': sortValues.houseNumber == 'desc'
            }"
          ></i>
        </th>

        <th scope="col" class="pointer" (click)="sortLocations('name')">
          Name
          <i
            *ngIf="sortValues.name"
            class="fa"
            [ngClass]="{
              'fa-arrow-down': sortValues.name == 'asc',
              'fa-arrow-up': sortValues.name == 'desc'
            }"
          ></i>
        </th>
        <th scope="col" class="pointer" (click)="sortLocations('zip')">
          Zip
          <i
            *ngIf="sortValues.zip"
            class="fa"
            [ngClass]="{
              'fa-arrow-down': sortValues.zip == 'asc',
              'fa-arrow-up': sortValues.zip == 'desc'
            }"
          ></i>
        </th>
        <th scope="col" class="pointer" (click)="sortLocations('city')">
          City
          <i
            *ngIf="sortValues.city"
            class="fa"
            [ngClass]="{
              'fa-arrow-down': sortValues.city == 'asc',
              'fa-arrow-up': sortValues.city == 'desc'
            }"
          ></i>
        </th>
        <th scope="col" class="pointer" (click)="sortLocations('country')">
          Country
          <i
            *ngIf="sortValues.country"
            class="fa"
            [ngClass]="{
              'fa-arrow-down': sortValues.country == 'asc',
              'fa-arrow-up': sortValues.country == 'desc'
            }"
          ></i>
        </th>
        <th scope="col" class="pointer" (click)="sortLocations('usageCount')">
          Usage count
          <i
            *ngIf="sortValues.usageCount"
            class="fa"
            [ngClass]="{
              'fa-arrow-down': sortValues.usageCount == 'asc',
              'fa-arrow-up': sortValues.usageCount == 'desc'
            }"
          ></i>
        </th>
        <th
          scope="col"
          class="pointer"
          (click)="sortLocations('score')"
          *ngIf="searchForm.controls['resultType'].value == 'automatic'"
        >
          Score
          <i
            *ngIf="sortValues.score"
            class="fa"
            [ngClass]="{
              'fa-arrow-down': sortValues.score == 'asc',
              'fa-arrow-up': sortValues.score == 'desc'
            }"
          ></i>
        </th>
        <th scope="col" class="pointer" (click)="sortLocations('createdAt')">
          Created at
          <i
            *ngIf="sortValues.createdAt"
            class="fa"
            [ngClass]="{
              'fa-arrow-down': sortValues.createdAt == 'asc',
              'fa-arrow-up': sortValues.createdAt == 'desc'
            }"
          ></i>
        </th>
        <th scope="col"></th>
        <th scope="col" class="pointer" (click)="sortLocations('lastUsedAt')">
          Last used
          <i
            *ngIf="sortValues.lastUsedAt"
            class="fa"
            [ngClass]="{
              'fa-arrow-down': sortValues.lastUsedAt == 'asc',
              'fa-arrow-up': sortValues.lastUsedAt == 'desc'
            }"
          ></i>
        </th>
        <!--
        <th scope="col" class="pointer" (click)="sortLocations('lastModifiedAt')">
          Last modified
          <i *ngIf="sortValues.lastModifiedAt" class="fa" [ngClass]="{'fa-arrow-down': sortValues.lastModifiedAt == 'asc', 'fa-arrow-up': sortValues.lastModifiedAt == 'desc'}"></i>
        </th>
        -->
      </tr>
    </thead>
    <tbody>
      <div *ngIf="loading" class="d-table-row table-spiner">
        <ngx-spinner [fullScreen]="false"> </ngx-spinner>
      </div>
      <ng-container *ngIf="!loading && locationContent">
        <ng-container
          *ngFor="let location of locationContent.content; trackBy: trackById"
        >
          <tr scope="row" (click)="toggleRow(location.extras.geodata_id)">
            <td class="expand-column" *ngIf="tabIndexSelected == 1">
              <span
                *ngIf="
                  location.extras.geodata_id === expandedRowIndex;
                  else down
                "
                ><mat-icon>expand_less</mat-icon></span
              >
              <ng-template #down>
                <mat-icon>expand_more</mat-icon>
              </ng-template>
            </td>
            <td>{{ location.location?.type }}</td>
            <td>
              {{ location.address?.street }} {{ location.address?.street2 }}
            </td>
            <td>{{ location.address?.house_no }}</td>
            <td>{{ location.address?.name }} {{ location.address?.name2 }}</td>
            <td>{{ location.address?.zip }}</td>
            <td>{{ location.address?.city }}</td>
            <td>{{ location.address?.country?.code }}</td>
            <td>{{ location.extras?.usage_count }}</td>
            <td *ngIf="searchForm.controls['resultType'].value == 'automatic'">
              {{ location.score }}
            </td>
            <td>
              {{ location.extras?.created_on | date : "dd LLLL yyyy HH:mm" }}
            </td>
            <td style="padding-top: 5px">
              <avatar
                [username]="
                  location.extras?.modified_by ?? location.extras?.created_by
                "
              ></avatar>
            </td>
            <td>
              {{ location.extras?.last_used | date : "dd LLLL yyyy HH:mm" }}
            </td>
            <td align="right">
              <button
                *ngIf="location.extras?.geodata_id"
                (click)="onEdit(location)"
                class="btn btn-ancotrans"
              >
                <i class="fa fa-edit"></i>
              </button>
              <button class="btn btn-danger" (click)="onDelete(location)">
                <i class="fa fa-remove"></i>
              </button>
            </td>
          </tr>

          <tr
            [@openClose]="isOpen ? 'open' : 'closed'"
            *ngIf="expandedRowIndex === location.extras.geodata_id"
            class="table-expanded-row"
          >
            <td [colSpan]="18">
              <div #rowDetails class="table-row-details justify-content-center">
                <div class="container">
                  <div class="text-center mb-1">
                    Last validation check date:
                    {{
                      location.extras?.last_validation_check
                        | date : "dd LLLL yyyy HH:mm"
                    }}
                    <p class="ml-auto">
                      Is Candidate:
                      <i
                        class="fa"
                        [ngClass]="
                          location.extras?.is_candidate
                            ? 'fa-check-square'
                            : 'fa-times'
                        "
                        aria-hidden="true"
                      ></i>
                    </p>
                  </div>
                  <div class="row">
                    <div class="col-2 offset-5 mb-1">
                      <button
                        *ngIf="
                          location.extras.validation_state !==
                          validationStatuses.VARIATION_FOUND
                        "
                        [disabled]="
                          !location.address.geo_decimal.lng ||
                          !location.address.geo_decimal.lat
                        "
                        (click)="
                          validateLocationData(location.extras.geodata_id)
                        "
                        class="btn btn-ancotrans table-button__width"
                      >
                        VALIDATE
                      </button>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-2">
                      <div class="table-row-details-group offset-4">
                        <div class="table-row-details-item">
                          <span class="table-row-details-item__label"
                            >&nbsp;</span
                          >
                          <span>STREET:</span>
                          <span>NUMBER:</span>
                          <span>ZIP:</span>
                          <span>CITY:</span>
                          <span>CODE:</span>
                          <span>LNG:</span>
                          <span>LAT:</span>
                        </div>
                      </div>
                    </div>
                    <div class="col">
                      <div class="table-row-details-group offset-2">
                        <div class="table-row-details-item">
                          <span class="table-row-details-item__label"
                            >LOCAL DATA</span
                          >
                          <span>{{ location.address?.street }}</span>
                          <span>{{ location.address?.house_no }}</span>
                          <span>{{ location.address?.zip }}</span>
                          <span>{{ location.address?.city }}</span>
                          <span>{{ location.address?.country?.code }}</span>
                          <span>{{ location.address?.geo_decimal?.lng }}</span>
                          <span>{{ location.address?.geo_decimal?.lat }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col">
                      <div class="table-row-details-group offset-2">
                        <div class="table-row-details-item">
                          <span class="table-row-details-item__label"
                            >SUGGESTED DATA</span
                          >
                          <ng-container
                            *ngIf="
                              location.extras.validation_state ===
                              validationStatuses.NOT_FOUND
                            "
                          >
                            <span>Suggested data not found</span>
                          </ng-container>
                          <ng-container
                            *ngIf="
                              location.extras.validation_state ===
                              validationStatuses.VARIATION_FOUND
                            "
                          >
                            <span
                              [ngClass]="{
                                'params-diffrence': isDiffrence(
                                  location.address?.street,
                                  suggestedDataChange?.street_name
                                )
                              }"
                              >{{ suggestedDataChange?.street_name }}</span
                            >
                            <span
                              [ngClass]="{
                                'params-diffrence': isDiffrence(
                                  location.address?.house_no,
                                  suggestedDataChange?.number
                                )
                              }"
                              >{{ suggestedDataChange?.number }}</span
                            >
                            <span
                              [ngClass]="{
                                'params-diffrence': isDiffrence(
                                  location.address?.zip,
                                  suggestedDataChange?.zip
                                )
                              }"
                              >{{ suggestedDataChange?.zip }}</span
                            >
                            <span
                              [ngClass]="{
                                'params-diffrence': isDiffrence(
                                  location.address?.city,
                                  suggestedDataChange?.city_name
                                )
                              }"
                              >{{ suggestedDataChange?.city_name }}</span
                            >
                            <span
                              [ngClass]="{
                                'params-diffrence': isDiffrence(
                                  location.address?.country?.code,
                                  suggestedDataChange?.country_code
                                )
                              }"
                              >{{ suggestedDataChange?.country_code }}</span
                            >
                            <span
                              [ngClass]="{
                                'params-diffrence': isDiffrence(
                                  location.address?.geo_decimal?.lng,
                                  suggestedDataChange?.lat_lon?.lng
                                )
                              }"
                              >{{ suggestedDataChange?.lat_lon?.lng }}</span
                            >
                            <span
                              [ngClass]="{
                                'params-diffrence': isDiffrence(
                                  location.address?.geo_decimal?.lat,
                                  suggestedDataChange?.lat_lon?.lat
                                )
                              }"
                              >{{ suggestedDataChange?.lat_lon?.lat }}</span
                            >
                          </ng-container>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="container">
                    <div class="row">
                      <div class="col-2 offset-4">
                        <button
                          *ngIf="
                            location.extras.validation_state ===
                            validationStatuses.VARIATION_FOUND
                          "
                          (click)="
                            approveSuggestedLocationData(
                              location.extras.geodata_id
                            )
                          "
                          class="btn btn-ancotrans table-button__width"
                        >
                          APPROVE
                        </button>
                      </div>
                      <div class="col-2">
                        <button
                          *ngIf="
                            location.extras.validation_state ===
                            validationStatuses.VARIATION_FOUND
                          "
                          (click)="
                            rejectSuggestedLocationData(
                              location.extras.geodata_id
                            )
                          "
                          class="btn btn-danger table-button__width"
                        >
                          REJECT
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </ng-container>
      </ng-container>
    </tbody>
  </table>

  <paging
    [offset]="offset"
    [limit]="limit"
    [itemsLength]="totalCount"
    (pageChanged)="pageChanged($event)"
  ></paging>
</ng-template>
