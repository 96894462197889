import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { of } from 'rxjs/internal/observable/of';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  switchMap,
  tap,
} from 'rxjs/operators';
import {
  CustomerInterfaceDrayos,
  CustomerInterfaceGeneric,
  CustomerInterfaceNav,
  CustomerResponseDrayos,
  CustomerResponseNav,
  CustomerResponsGenerice,
  CustomersLinkInterface,
} from 'src/app/models/customer.interface';
import { IdentityService } from 'src/app/services/identity.service';
import { ModalWindowComponent } from 'src/app/shared/modal-window/modal-window.component';

export interface LinkedCustomer {
  genCustomer: CustomerInterfaceGeneric | null;
  leftCustomer: CustomerInterfaceNav | null;
  rightCustomer: CustomerInterfaceDrayos | null;
  color: string;
}

export interface MergedCustomer {
  genCustomer: CustomerInterfaceGeneric;
  leftCustomer: CustomerInterfaceNav;
  rightCustomer: CustomerInterfaceDrayos;
}

@Component({
  selector: 'app-identity-service',
  templateUrl: './identity-service.component.html',
  styleUrls: ['./identity-service.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class IdentityServiceComponent implements OnInit {
  expandedElement:
    | CustomerInterfaceDrayos
    | CustomerInterfaceGeneric
    | CustomerInterfaceNav
    | null;

  expandedRowIndex: string = '';
  isOpen = true;

  isLoadingGeneric: boolean = false;
  isLoadingLeft: boolean = false;
  isLoadingRight: boolean = false;

  selectedLeftRow: CustomerInterfaceNav | null = null;
  selectedRightRow: CustomerInterfaceDrayos | null = null;
  selectedGenericRow: CustomerInterfaceGeneric | null = null;

  linkedCustomers: LinkedCustomer[] = [];

  isLinkedFilterNav: boolean | null = null;
  isLinkedFilterDrayos: boolean | null = null;

  leftDataSourceNav = new MatTableDataSource<CustomerInterfaceNav>([]);
  rightDataSource = new MatTableDataSource<CustomerInterfaceDrayos>([]);
  genericDataSource = new MatTableDataSource<CustomerInterfaceGeneric>([]);

  leftFilter: string = '';
  rightFilter: string = '';
  genFilter: string = '';
  filterSubject: Subject<string> = new Subject<string>();
  filterSubjectNav: Subject<string> = new Subject<string>();
  filterSubjectGen: Subject<string> = new Subject<string>();

  public isSyncActive: boolean = true;

  @ViewChild('leftPaginator') leftPaginator: MatPaginator;
  @ViewChild('rightPaginator') rightPaginator: MatPaginator;
  @ViewChild('genericPaginator') genericPaginator: MatPaginator;

  totalRecordsDrayos: number = 0;
  pageSizeDrayos: number = 10;
  pageIndexDrayos: number = 0;
  sortByDrayos: string = 'created';
  sortOrderDrayos: number = 0;
  searchStringDrayos: string = '';

  totalRecordsNav: number = 0;
  pageSizeNav: number = 10;
  pageIndexNav: number = 0;
  sortByNav: string = 'created';
  sortOrderNav: number = 0;
  searchStringNav: string = '';

  totalRecordsGen: number = 0;
  pageSizeGen: number = 10;
  pageIndexGen: number = 0;
  sortByGen: string = 'created';
  sortOrderGen: number = 0;
  searchStringGen: string = '';

  isShowingLeftTable: boolean = false;

  @ViewChildren('rowElement') rowElements: QueryList<ElementRef>;

  constructor(
    private bsModalService: BsModalService,
    private bsModalRef: BsModalRef,
    private identityService: IdentityService,
    private cdr: ChangeDetectorRef,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService
  ) {}

  toggleTableVisibility() {
    this.isShowingLeftTable = !!this.selectedGenericRow?.drayos_id;
    this.cdr.detectChanges();
  }

  public reset() {
    this.selectedGenericRow = null;
    this.selectedLeftRow = null;
    this.selectedRightRow = null;
    // this.clearGenFilter();
    // this.clearLeftFilter();
    // this.clearRightFilter();
  }

  ngOnInit() {
    this.leftDataSourceNav.paginator = this.leftPaginator;
    this.rightDataSource.paginator = this.rightPaginator;
    this.genericDataSource.paginator = this.genericPaginator;

    this.loadGenericTable().subscribe();
    this.loadLeftTable().subscribe();
    this.loadRightTable().subscribe();

    this.filterSubject
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        switchMap((filterValue) => {
          if (filterValue.length < 3) {
            this.searchStringDrayos = '';
          } else {
            this.searchStringDrayos = filterValue.trim().toLowerCase();
          }
          return this.loadRightTable();
        })
      )
      .subscribe();
    this.filterSubjectNav
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        switchMap((filterValue) => {
          if (filterValue.length < 3) {
            this.searchStringNav = '';
          } else {
            this.searchStringNav = filterValue.trim().toLowerCase();
          }

          return this.loadLeftTable();
        })
      )
      .subscribe();

    this.filterSubjectGen
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        switchMap((filterValue) => {
          if (filterValue.length < 3) {
            this.searchStringGen = '';
          } else {
            this.searchStringGen = filterValue.trim().toLowerCase();
          }

          return this.loadGenericTable();
        })
      )
      .subscribe();
  }

  toggleSpinnerDrayos(show: boolean) {
    this.isLoadingRight = show;
    if (show) {
      this.spinner.show();
    } else {
      this.spinner.hide();
    }
  }

  loadRightTable() {
    this.toggleSpinnerDrayos(true);
    return this.identityService
      .getCustomersDrayos(
        this.searchStringDrayos,
        this.sortByDrayos,
        this.sortOrderDrayos,
        this.pageIndexDrayos + 1,
        this.pageSizeDrayos,
        !this.isLinkedFilterDrayos
      )
      .pipe(
        tap((response: CustomerResponseDrayos) => {
          this.rightDataSource.data = response.data;
          this.totalRecordsDrayos = response.total;
          this.toggleSpinnerDrayos(false);
        }),
        catchError((error) => {
          this.toggleSpinnerDrayos(false);
          return of([]);
        })
      );
  }

  toggleSpinnerNav(show: boolean) {
    this.isLoadingLeft = show;
    if (show) {
      this.spinner.show();
    } else {
      this.spinner.hide();
    }
  }

  loadLeftTable() {
    this.toggleSpinnerNav(true);
    return this.identityService
      .getCustomersNav(
        this.searchStringNav,
        this.sortByNav,
        this.sortOrderNav,
        this.pageIndexNav + 1,
        this.pageSizeNav,
        !this.isLinkedFilterNav
      )
      .pipe(
        tap((response: CustomerResponseNav) => {
          this.leftDataSourceNav.data = response.data;
          this.totalRecordsNav = response.total;
          this.toggleSpinnerNav(false);
        }),
        catchError((error) => {
          this.toggleSpinnerNav(false);
          return of([]);
        })
      );
  }

  loadGenericTable() {
    return this.identityService
      .getCustomers(
        this.searchStringGen,
        this.sortByGen,
        this.sortOrderGen,
        this.pageIndexGen + 1,
        this.pageSizeGen
      )
      .pipe(
        tap((response: CustomerResponsGenerice) => {
          let data = response.data;

          if (this.selectedGenericRow) {
            const isSelectedRowInData = data.find(
              (item) => item.id === this.selectedGenericRow!.id
            );

            if (!isSelectedRowInData) {
              data = [this.selectedGenericRow, ...data];
            }
          }

          this.genericDataSource.data = data;
          this.totalRecordsGen = response.total;

          this.cdr.detectChanges();
        })
      );
  }

  applySortNav(sortState: Sort) {
    this.sortByNav = sortState.active;
    this.sortOrderNav = sortState.direction === 'asc' ? 1 : -1;
    this.loadLeftTable().subscribe();
  }

  pageChangedNav(event: any) {
    this.pageIndexNav = event.pageIndex;
    this.pageSizeNav = event.pageSize;
    this.loadLeftTable().subscribe();
  }
  applySortGen(sortState: Sort) {
    this.sortByGen = sortState.active;
    this.sortOrderGen = sortState.direction === 'asc' ? 1 : -1;
    this.loadGenericTable().subscribe(() => {});
  }

  pageChangedGen(event: any) {
    this.pageIndexGen = event.pageIndex;
    this.pageSizeGen = event.pageSize;
    this.loadGenericTable().subscribe();
  }

  applySort(sortState: Sort) {
    this.sortByDrayos = sortState.active;
    this.sortOrderDrayos = sortState.direction === 'asc' ? 1 : -1;
    this.loadRightTable().subscribe();
  }

  pageChangedDrayos(event: any) {
    this.pageIndexDrayos = event.pageIndex;
    this.pageSizeDrayos = event.pageSize;
    this.loadRightTable().subscribe();
  }

  applyLeftFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.leftFilter = filterValue;
    this.searchStringNav = filterValue;
    this.filterSubjectNav.next(filterValue);

    // if (this.isSyncActive) {
    //   this.rightFilter = filterValue;
    //   this.searchStringDrayos = filterValue;
    //   this.rightDataSource.filter = filterValue.trim().toLowerCase();
    //   this.filterSubject.next(filterValue);
    // }
  }

  applyRightFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.rightFilter = filterValue;
    this.searchStringDrayos = filterValue;
    this.filterSubject.next(filterValue);

    // if (this.isSyncActive) {
    //   this.leftFilter = filterValue;
    //   this.searchStringNav = filterValue;
    //   this.leftDataSourceNav.filter = filterValue.trim().toLowerCase();
    //   this.filterSubjectNav.next(filterValue);
    // }
  }

  applyGenFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.genFilter = filterValue;
    this.searchStringGen = filterValue;
    this.filterSubjectGen.next(filterValue);

    if (this.isSyncActive) {
      if (this.isShowingLeftTable) {
        this.leftFilter = filterValue;
        this.searchStringNav = filterValue;
        this.leftDataSourceNav.filter = filterValue.trim().toLowerCase();
        this.filterSubjectNav.next(filterValue);
      } else {
        this.rightFilter = filterValue;
        this.searchStringDrayos = filterValue;
        this.rightDataSource.filter = filterValue.trim().toLowerCase();
        this.filterSubject.next(filterValue);
      }
    }

    this.loadGenericTable().subscribe(() => {});
  }

  selectGenRow(row: CustomerInterfaceGeneric) {
    this.selectedLeftRow = null;
    this.selectedRightRow = null;
    this.selectedGenericRow = this.selectedGenericRow === row ? null : row;

    if (!this.selectedGenericRow) {
      this.clearLeftFilter();
      this.clearRightFilter();
    }
    this.toggleTableVisibility();
  }

  selectLeftRow(row: CustomerInterfaceNav) {
    if (row.is_linked) {
      return; // blokada rekordu- dodatkowa
    }
    this.selectedLeftRow = this.selectedLeftRow === row ? null : row;
  }

  toggleRowExpansion(row: any) {
    this.expandedElement = this.expandedElement === row ? null : row;
  }

  selectRightRow(row: CustomerInterfaceDrayos) {
    if (row.is_linked) {
      return;
    }
    this.selectedRightRow = this.selectedRightRow === row ? null : row;
  }

  copyCompany() {
    if (this.selectedLeftRow) {
      this.bsModalRef = this.bsModalService.show(ModalWindowComponent, {
        class: 'modal-lg',
        initialState: {
          showContinueButton: true,
          title: 'Verification',
          data: `Do you want to copy this company?`,
        },

        backdrop: 'static',
      });

      this.bsModalRef.content.onContinueAction
        .pipe(
          switchMap((result: boolean) => {
            if (result) {
              const newCompany = { ...this.selectedLeftRow };
              this.rightDataSource.data = [
                ...(this.rightDataSource.data as any),
                newCompany,
              ];
              this.selectedLeftRow = null;

              return of(true);
            } else {
              return of(null);
            }
          })
        )
        .subscribe();
    }
  }

  linkCompanies() {
    this.bsModalRef = this.bsModalService.show(ModalWindowComponent, {
      class: 'modal-lg',
      initialState: {
        showContinueButton: true,
        title: 'Verification',
        data: `Are you sure about the link between this 2 companies?`,
      },
      backdrop: 'static',
    });

    this.bsModalRef.content.onContinueAction
      .pipe(
        switchMap((result: boolean) => {
          if (result) {
            if (
              this.selectedGenericRow &&
              (this.selectedLeftRow || this.selectedRightRow)
            ) {
              if (this.selectedLeftRow) {
                return this.identityService
                  .linkCustomersNav(
                    this.selectedGenericRow.id,
                    this.selectedLeftRow.no
                  )
                  .pipe(
                    tap(() => {
                      this.linkedCustomers.push({
                        genCustomer: this.selectedGenericRow,
                        rightCustomer: this.selectedRightRow,
                        leftCustomer: this.selectedLeftRow,
                        color: '',
                      });

                      this.selectedLeftRow = null;
                      this.selectedRightRow = null;
                    })
                  );
              } else {
                return this.identityService
                  .linkCustomersDrayos(
                    this.selectedGenericRow.id,
                    this.selectedRightRow?.id
                  )
                  .pipe(
                    tap(() => {
                      this.linkedCustomers.push({
                        genCustomer: this.selectedGenericRow,
                        rightCustomer: this.selectedRightRow,
                        leftCustomer: this.selectedLeftRow,
                        color: '',
                      });

                      this.selectedLeftRow = null;
                      this.selectedRightRow = null;
                    })
                  );
              }
            } else {
              alert('One of the companies is already linked.');
              return of(null);
            }
          }

          return of(null);
        })
      )
      .subscribe({
        next: (response: any) => {
          if (response.status === 200 || response.status === 201) {
            this.toastr.success(
              'Companies successfully linked and updated.',
              'Success'
            );
            this.reset();
          }
          if (response.status === 409) {
            this.toastr.info(response.detail);
          }
        },
        error: (err: any) => {
          console.log(err);
          this.toastr.error(`Error: ${err.error.title}`, 'Failure');
        },
      });
  }

  getLinkedColor(row: any, side: 'left' | 'right'): string | null {
    const link = this.linkedCustomers.find(
      (link: any) => link[side + 'Customer'] === row
    );
    return link ? link.color : null;
  }

  clearLeftFilter() {
    this.leftFilter = '';
    this.searchStringNav = '';
    this.leftDataSourceNav.filter = '';
    this.filterSubjectNav.next('');

    if (this.isSyncActive) {
      this.rightFilter = '';
      this.searchStringDrayos = '';
      this.rightDataSource.filter = '';
      this.filterSubject.next('');
    }
  }

  clearRightFilter() {
    this.rightFilter = '';
    this.searchStringDrayos = '';
    this.rightDataSource.filter = '';
    this.filterSubject.next('');

    if (this.isSyncActive) {
      this.leftFilter = '';
      this.searchStringNav = '';
      this.leftDataSourceNav.filter = '';
      this.filterSubjectNav.next('');
    }
  }

  clearGenFilter() {
    this.genFilter = '';
    this.searchStringGen = '';
    this.filterSubjectGen.next('');

    if (this.isSyncActive) {
      if (this.isShowingLeftTable) {
        this.leftFilter = '';
        this.searchStringNav = '';
        this.leftDataSourceNav.filter = '';
        this.filterSubjectNav.next('');
      } else {
        this.rightFilter = '';
        this.searchStringDrayos = '';
        this.rightDataSource.filter = '';
        this.filterSubject.next('');
      }
    }
  }

  syncFilters() {
    if (
      this.genFilter &&
      (this.leftFilter || this.rightFilter) &&
      this.selectedGenericRow
    ) {
      if (this.isShowingLeftTable) {
        const valueToSync = this.leftFilter;
        this.leftFilter = valueToSync;
        this.genFilter = valueToSync;
        this.leftDataSourceNav.filter = valueToSync.trim().toLowerCase();
        this.genericDataSource.filter = valueToSync.trim().toLowerCase();
      } else {
        const valueToSync = this.rightFilter;
        this.rightFilter = valueToSync;
        this.genFilter = valueToSync;
        this.rightDataSource.filter = valueToSync.trim().toLowerCase();
        this.genericDataSource.filter = valueToSync.trim().toLowerCase();
      }
    }
  }

  toggleSync() {
    this.isSyncActive = !this.isSyncActive;
  }

  generateUniqueColor(): string {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }
}
