import { Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Auth, Hub } from 'aws-amplify';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
})
export class AuthComponent implements OnInit {
  constructor(
    private router: Router,
    private zone: NgZone,
    private spinner: NgxSpinnerService,
    private authService: AuthService
  ) {
    //Used for listening to login events
    Hub.listen('auth', ({ payload: { event, data } }) => {
      if (event === 'signIn') {
        this.spinner.show();
        this.zone.run(() => this.router.navigate(['/locations']));
      } else {
        this.spinner.hide();
      }
    });

    //currentAuthenticatedUser: when user comes to login page again
    Auth.currentAuthenticatedUser()
      .then(() => {
        this.router.navigate(['/locations'], { replaceUrl: true });
      })
      .catch((err) => {
        this.spinner.hide();
      });
  }

  ngOnInit(): void {}
}
