<div class="tables-container">
  <div class="table-section left-section">
    <mat-form-field appearance="outline" class="filter-field">
      <mat-label>Filter Dynamics</mat-label>
      <input
        matInput
        (input)="applyGenFilter($event)"
        [(ngModel)]="genFilter"
        placeholder=""
      />
      <button
        mat-icon-button
        matSuffix
        *ngIf="genFilter"
        (click)="clearGenFilter()"
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  </div>
  <ng-container *ngIf="selectedGenericRow">
    <div class="sync-icon">
      <button
        mat-icon-button
        aria-label="Toggle Sync"
        (click)="toggleSync()"
        matTooltip="Filtering synchronization"
      >
        <mat-icon>{{ isSyncActive ? "sync" : "sync_disabled" }}</mat-icon>
      </button>
    </div>

    <!-- DRAYOS FILTER -->
    <div class="table-section right-section" *ngIf="!isShowingLeftTable">
      <mat-form-field appearance="outline" class="filter-field">
        <mat-label>Filter Drayos</mat-label>
        <input
          matInput
          (input)="applyRightFilter($event)"
          [(ngModel)]="rightFilter"
          placeholder=""
        />
        <button
          mat-icon-button
          matSuffix
          *ngIf="rightFilter"
          (click)="clearRightFilter()"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <!-- NAV FILTER -->
    <div class="table-section right-section" *ngIf="isShowingLeftTable">
      <mat-form-field appearance="outline" class="filter-field">
        <mat-label>Filter NAV</mat-label>
        <input
          matInput
          (input)="applyLeftFilter($event)"
          [(ngModel)]="leftFilter"
          placeholder=""
        />
        <button
          mat-icon-button
          matSuffix
          *ngIf="leftFilter"
          (click)="clearLeftFilter()"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </div>
  </ng-container>
</div>

<div class="tables-container">
  <div class="tables-container">
    <!-- Dynamics -->
    <div class="table-section">
      <table
        mat-table
        [dataSource]="genericDataSource"
        multiTemplateDataRows
        matSort
        (matSortChange)="applySortGen($event)"
        class="mat-elevation-z8 full-width-table"
      >
        <ng-container matColumnDef="expandIcon">
          <th mat-header-cell *matHeaderCellDef aria-label="row actions">
            &nbsp;
          </th>
          <td mat-cell *matCellDef="let element">
            <button
              mat-icon-button
              aria-label="expand row"
              (click)="
                expandedElement = expandedElement === element ? null : element;
                $event.stopPropagation()
              "
            >
              <mat-icon>
                {{
                  expandedElement === element ? "expand_less" : "expand_more"
                }}
              </mat-icon>
            </button>
          </td>
        </ng-container>

        <ng-container matColumnDef="expandedDetail">
          <td mat-cell *matCellDef="let element" [attr.colspan]="7">
            <div
              class="example-element-detail"
              [@detailExpand]="
                element == expandedElement ? 'expanded' : 'collapsed'
              "
            >
              <div class="table-row-details grid-container w-100">
                <div class="grid-item">
                  <strong>Name:</strong> {{ element.name }}
                </div>
                <div class="grid-item">
                  <strong>Email:</strong> {{ element.email ?? "No data" }}
                </div>
                <div class="grid-item">
                  <strong>Phone:</strong> {{ element.phone ?? "No data" }}
                </div>
                <div class="grid-item">
                  <strong>Address:</strong> {{ element.address ?? "No data" }}
                </div>

                <div class="grid-item">
                  <strong>ZIP:</strong> {{ element.zip ?? "No data" }}
                </div>
                <div class="grid-item">
                  <strong>City:</strong> {{ element.city ?? "No data" }}
                </div>
                <div class="grid-item">
                  <strong>Country:</strong> {{ element.country ?? "No data" }}
                </div>
                <div class="grid-item">
                  <strong>Created:</strong>
                  {{ element.created | date : "dd LLLL yyyy HH:mm" }}
                </div>
                <div class="grid-item">
                  <strong>Modified:</strong>
                  {{ element.modified | date : "dd LLLL yyyy HH:mm" }}
                </div>
                <div class="grid-item">
                  <strong>Status:</strong> {{ element.status ?? "No data" }}
                </div>
                <div class="grid-item">
                  <strong>Fax:</strong> {{ element.fax ?? "No data" }}
                </div>
                <div class="grid-item">
                  <strong>Drayos ID:</strong>
                  {{ element.drayos_id ?? "No data" }}
                </div>
                <div class="grid-item">
                  <strong>ID:</strong> {{ element.id ?? "No data" }}
                </div>
                <div class="grid-item">
                  <strong>NAV ID:</strong> {{ element.nav_id ?? "No data" }}
                </div>
                <div class="grid-item">
                  <strong>Extra Info:</strong>
                  {{ element.extra_info ?? "No data" }}
                </div>
              </div>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="customername">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Customer</th>
          <td
            mat-cell
            *matCellDef="let element"
            [ngClass]="{ 'selected-row': selectedGenericRow === element }"
            [ngStyle]="{
              'background-color':
                getLinkedColor(element, 'left') || 'transparent'
            }"
          >
            {{ element.name }}
          </td>
        </ng-container>
        <ng-container matColumnDef="nav_id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Nav ID</th>
          <td
            mat-cell
            *matCellDef="let element"
            [ngClass]="{ 'selected-row': selectedGenericRow === element }"
            [ngStyle]="{
              'background-color':
                getLinkedColor(element, 'left') || 'transparent'
            }"
          >
            {{ element.nav_id }}
          </td>
        </ng-container>

        <ng-container matColumnDef="created">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Created</th>
          <td
            mat-cell
            *matCellDef="let element"
            [ngClass]="{ 'selected-row': selectedGenericRow === element }"
            [ngStyle]="{
              'background-color':
                getLinkedColor(element, 'left') || 'transparent'
            }"
          >
            {{ (element.created | date : "dd LLLL yyyy HH:mm") ?? "no data" }}
          </td>
        </ng-container>

        <ng-container matColumnDef="modified">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Modified</th>
          <td
            mat-cell
            *matCellDef="let element"
            [ngClass]="{ 'selected-row': selectedGenericRow === element }"
            [ngStyle]="{
              'background-color':
                getLinkedColor(element, 'left') || 'transparent'
            }"
          >
            {{ (element.modified | date : "dd LLLL yyyy HH:mm") ?? "no data" }}
          </td>
        </ng-container>

        <tr
          mat-header-row
          *matHeaderRowDef="[
            'expandIcon',
            'customername',
            'nav_id',
            'created',
            'modified'
          ]"
        ></tr>
        <tr
          mat-row
          *matRowDef="
            let element;
            columns: [
              'expandIcon',
              'customername',
              'nav_id',
              'created',
              'modified'
            ]
          "
          (click)="selectGenRow(element)"
          #rowElement
          [ngClass]="{ 'selected-row': selectedGenericRow?.id === element.id }"
        ></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: ['expandedDetail']"
          class="example-detail-row"
        ></tr>
      </table>

      <mat-paginator
        [length]="totalRecordsGen"
        [pageSize]="pageSizeGen"
        [pageSizeOptions]="[5, 10, 25, 50]"
        (page)="pageChangedGen($event)"
      >
      </mat-paginator>
    </div>
  </div>

  <div class="actions-section" *ngIf="selectedGenericRow">
    <button
      mat-icon-button
      aria-label="Link"
      (click)="linkCompanies()"
      matTooltip="Link companies data"
      [ngClass]="{
        'func-active':
          selectedGenericRow && (selectedLeftRow || selectedRightRow)
      }"
      [disabled]="!selectedLeftRow && !selectedRightRow"
    >
      <mat-icon class="large-icon">link</mat-icon>
    </button>
  </div>

  <div class="tables-container" *ngIf="selectedGenericRow">
    <!-- Dynamics -->
    <div class="table-section" *ngIf="isShowingLeftTable">
      <div class="table-wrapper">
        <div *ngIf="isLoadingLeft" class="table-spinner-container">
          <ngx-spinner [fullScreen]="false"> </ngx-spinner>
        </div>
        <table
          *ngIf="!isLoadingLeft"
          mat-table
          [dataSource]="leftDataSourceNav"
          multiTemplateDataRows
          matSort
          (matSortChange)="applySortNav($event)"
          class="mat-elevation-z8 full-width-table"
        >
          <ng-container matColumnDef="expandIcon">
            <th mat-header-cell *matHeaderCellDef aria-label="row actions">
              &nbsp;
            </th>
            <td mat-cell *matCellDef="let element">
              <button
                mat-icon-button
                aria-label="expand row"
                (click)="
                  expandedElement =
                    expandedElement === element ? null : element;
                  $event.stopPropagation()
                "
              >
                <mat-icon>
                  {{
                    expandedElement === element ? "expand_less" : "expand_more"
                  }}
                </mat-icon>
              </button>
            </td>
          </ng-container>

          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef aria-label="row actions">
              Status
              <mat-checkbox
                [(ngModel)]="isLinkedFilterNav"
                (change)="loadLeftTable().subscribe()"
              >
                Filter Linked
              </mat-checkbox>
            </th>
            <td
              mat-cell
              *matCellDef="let element"
              style="width: 20px; text-align: center"
            >
              <mat-icon
                [ngClass]="{
                  'icon-linked': element.is_linked,
                  'icon-unlinked': !element.is_linked
                }"
                [matTooltip]="
                  element.is_linked ? 'Already linked' : 'Can be linked'
                "
              >
                {{ element.is_linked ? "link" : "check_circle" }}
              </mat-icon>
            </td>
          </ng-container>

          <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element" [attr.colspan]="7">
              <div
                class="example-element-detail"
                [@detailExpand]="
                  element == expandedElement ? 'expanded' : 'collapsed'
                "
              >
                <div class="table-row-details grid-container">
                  <div class="grid-item">
                    <strong>No:</strong> {{ element.no }}
                  </div>
                  <div class="grid-item">
                    <strong>Name:</strong> {{ element.name }}
                  </div>
                  <div class="grid-item">
                    <strong>Email:</strong> {{ element.email ?? "No data" }}
                  </div>
                  <div class="grid-item">
                    <strong>Phone:</strong> {{ element.phone ?? "No data" }}
                  </div>
                  <div class="grid-item">
                    <strong>Address:</strong> {{ element.address ?? "No data" }}
                  </div>

                  <div class="grid-item">
                    <strong>ZIP:</strong> {{ element.zip ?? "No data" }}
                  </div>
                  <div class="grid-item">
                    <strong>City:</strong> {{ element.city ?? "No data" }}
                  </div>
                  <div class="grid-item">
                    <strong>Country:</strong> {{ element.country ?? "No data" }}
                  </div>
                  <div class="grid-item">
                    <strong>Created:</strong>
                    {{ element.created | date : "dd LLLL yyyy HH:mm" }}
                  </div>
                  <div class="grid-item">
                    <strong>Modified:</strong>
                    {{ element.modified | date : "dd LLLL yyyy HH:mm" }}
                  </div>
                  <div class="grid-item">
                    <strong>Status:</strong> {{ element.status ?? "No data" }}
                  </div>

                  <div class="grid-item">
                    <strong>Fax:</strong> {{ element.fax ?? "No data" }}
                  </div>
                </div>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="customername">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Customer</th>
            <td
              mat-cell
              *matCellDef="let element"
              [ngClass]="{
                'selected-row':
                  selectedLeftRow?.no === element.id && !element.is_linked,
                'linked-row': element.is_linked
              }"
              (click)="!element.is_linked && selectLeftRow(element)"
              [ngStyle]="{
                'background-color':
                  getLinkedColor(element, 'left') || 'transparent'
              }"
            >
              {{ element.name }}
            </td>
          </ng-container>

          <ng-container matColumnDef="no">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>No.</th>
            <td
              mat-cell
              *matCellDef="let element"
              [ngClass]="{
                'selected-row':
                  selectedLeftRow?.no === element.id && !element.is_linked,
                'linked-row': element.is_linked
              }"
              (click)="!element.is_linked && selectLeftRow(element)"
              [ngStyle]="{
                'background-color':
                  getLinkedColor(element, 'left') || 'transparent'
              }"
            >
              {{ element.no }}
            </td>
          </ng-container>
          <ng-container matColumnDef="created">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Created</th>
            <td
              mat-cell
              *matCellDef="let element"
              [ngClass]="{
                'selected-row':
                  selectedLeftRow?.no === element.id && !element.is_linked,
                'linked-row': element.is_linked
              }"
              (click)="!element.is_linked && selectLeftRow(element)"
              [ngStyle]="{
                'background-color':
                  getLinkedColor(element, 'left') || 'transparent'
              }"
            >
              {{ (element.created | date : "dd LLLL yyyy HH:mm") ?? "no data" }}
            </td>
          </ng-container>

          <ng-container matColumnDef="modified">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Modified</th>
            <td
              mat-cell
              *matCellDef="let element"
              [ngClass]="{
                'selected-row':
                  selectedLeftRow?.no === element.id && !element.is_linked,
                'linked-row': element.is_linked
              }"
              (click)="!element.is_linked && selectLeftRow(element)"
              [ngStyle]="{
                'background-color':
                  getLinkedColor(element, 'left') || 'transparent'
              }"
            >
              {{
                (element.modified | date : "dd LLLL yyyy HH:mm") ?? "no data"
              }}
            </td>
          </ng-container>

          <tr
            mat-header-row
            *matHeaderRowDef="[
              'expandIcon',
              'status',
              'customername',
              'no',
              'created',
              'modified'
            ]"
          ></tr>
          <tr
            mat-row
            *matRowDef="
              let element;
              columns: [
                'expandIcon',
                'status',
                'customername',
                'no',
                'created',
                'modified'
              ]
            "
          ></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: ['expandedDetail']"
            class="example-detail-row"
          ></tr>
        </table>

        <mat-paginator
          [length]="totalRecordsNav"
          [pageSize]="pageSizeNav"
          [pageSizeOptions]="[5, 10, 25, 50]"
          (page)="pageChangedNav($event)"
        >
        </mat-paginator>
      </div>
    </div>

    <!--  Drayos -->
    <div class="table-section right-section" *ngIf="!isShowingLeftTable">
      <div class="table-wrapper">
        <div *ngIf="isLoadingRight" class="table-spinner-container">
          <ngx-spinner [fullScreen]="false"> </ngx-spinner>
        </div>
        <div class="table-section">
          <table
            mat-table
            [dataSource]="rightDataSource"
            multiTemplateDataRows
            matSort
            (matSortChange)="applySort($event)"
            class="mat-elevation-z8 full-width-table"
          >
            <ng-container matColumnDef="expandIcon">
              <th mat-header-cell *matHeaderCellDef aria-label="row actions">
                &nbsp;
              </th>
              <td mat-cell *matCellDef="let element">
                <button
                  mat-icon-button
                  aria-label="expand row"
                  (click)="
                    expandedElement =
                      expandedElement === element ? null : element;
                    $event.stopPropagation()
                  "
                >
                  <mat-icon>
                    {{
                      expandedElement === element
                        ? "expand_less"
                        : "expand_more"
                    }}
                  </mat-icon>
                </button>
              </td>
            </ng-container>

            <!-- Kolumna status dla ikony is_linked -->
            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef aria-label="row actions">
                Status
                <mat-checkbox
                  [(ngModel)]="isLinkedFilterDrayos"
                  (change)="loadRightTable().subscribe()"
                >
                  Filter Linked
                </mat-checkbox>
              </th>
              <td
                mat-cell
                *matCellDef="let element"
                style="width: 20px; text-align: center"
              >
                <mat-icon
                  [ngClass]="{
                    'icon-linked': element.is_linked,
                    'icon-unlinked': !element.is_linked
                  }"
                  [matTooltip]="
                    element.is_linked ? 'Already linked' : 'Can be linked'
                  "
                >
                  {{ element.is_linked ? "link" : "check_circle" }}
                </mat-icon>
              </td>
            </ng-container>

            <ng-container matColumnDef="expandedDetail">
              <td mat-cell *matCellDef="let element" [attr.colspan]="7">
                <div
                  class="example-element-detail"
                  [@detailExpand]="
                    element == expandedElement ? 'expanded' : 'collapsed'
                  "
                >
                  <div class="table-row-details grid-container">
                    <div class="grid-item">
                      <strong>ID:</strong> {{ element.id }}
                    </div>
                    <div class="grid-item">
                      <strong>Email:</strong> {{ element.email ?? "No data" }}
                    </div>
                    <div class="grid-item">
                      <strong>Phone:</strong> {{ element.phone ?? "No data" }}
                    </div>
                    <div class="grid-item">
                      <strong>Address:</strong>
                      {{ element.address ?? "No data" }}
                    </div>

                    <div class="grid-item">
                      <strong>ZIP:</strong> {{ element.zip ?? "No data" }}
                    </div>
                    <div class="grid-item">
                      <strong>City:</strong> {{ element.city ?? "No data" }}
                    </div>
                    <div class="grid-item">
                      <strong>Country:</strong>
                      {{ element.country ?? "No data" }}
                    </div>
                    <div class="grid-item">
                      <strong>Created:</strong>
                      {{ element.created | date : "dd LLLL yyyy HH:mm" }}
                    </div>

                    <div class="grid-item">
                      <strong>Modified:</strong>
                      {{ element.modified | date : "dd LLLL yyyy HH:mm" }}
                    </div>
                    <div class="grid-item">
                      <strong>Status:</strong> {{ element.status ?? "No data" }}
                    </div>
                    <!-- <div class="grid-item">
                  <strong>Nav ID:</strong> {{ element.nav_id ?? 'No data' }}
                </div> -->
                    <!-- <div class="grid-item">
                  <strong>Id:</strong> {{ element.id ?? 'No data' }}
                </div> -->

                    <div class="grid-item">
                      <strong>Fax:</strong> {{ element.fax ?? "No data" }}
                    </div>
                    <div class="grid-item">
                      <strong>External system ID:</strong>
                      {{ element.external_system_id ?? "No data" }}
                    </div>
                  </div>
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="customername">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Customer
              </th>
              <td
                mat-cell
                *matCellDef="let element"
                [ngClass]="{
                  'selected-row':
                    selectedRightRow?.id === element.id && !element.is_linked,
                  'linked-row': element.is_linked
                }"
                [ngStyle]="{
                  'background-color':
                    getLinkedColor(element, 'right') || 'transparent'
                }"
                (click)="!element.is_linked && selectRightRow(element)"
              >
                {{ element.name }}
              </td>
            </ng-container>

            <ng-container matColumnDef="external_system_id">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                External ID
              </th>
              <td
                mat-cell
                *matCellDef="let element"
                [ngClass]="{
                  'selected-row':
                    selectedRightRow?.id === element.id && !element.is_linked,
                  'linked-row': element.is_linked
                }"
                [ngStyle]="{
                  'background-color':
                    getLinkedColor(element, 'right') || 'transparent'
                }"
                (click)="!element.is_linked && selectRightRow(element)"
              >
                {{ element.external_system_id }}
              </td>
            </ng-container>

            <ng-container matColumnDef="id">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Drayos ID
              </th>
              <td
                mat-cell
                *matCellDef="let element"
                [ngClass]="{
                  'selected-row':
                    selectedRightRow?.id === element.id && !element.is_linked,
                  'linked-row': element.is_linked
                }"
                [ngStyle]="{
                  'background-color':
                    getLinkedColor(element, 'right') || 'transparent'
                }"
                (click)="!element.is_linked && selectRightRow(element)"
              >
                {{ element.id ?? "no data" }}
              </td>
            </ng-container>

            <ng-container matColumnDef="created">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Created</th>
              <td
                mat-cell
                *matCellDef="let element"
                [ngClass]="{
                  'selected-row':
                    selectedRightRow?.id === element.id && !element.is_linked,
                  'linked-row': element.is_linked
                }"
                [ngStyle]="{
                  'background-color':
                    getLinkedColor(element, 'right') || 'transparent'
                }"
                (click)="!element.is_linked && selectRightRow(element)"
              >
                {{
                  (element.created | date : "dd LLLL yyyy HH:mm") ?? "no data"
                }}
              </td>
            </ng-container>

            <ng-container matColumnDef="modified">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Modified
              </th>
              <td
                mat-cell
                *matCellDef="let element"
                [ngClass]="{
                  'selected-row':
                    selectedRightRow?.id === element.id && !element.is_linked,
                  'linked-row': element.is_linked
                }"
                (click)="!element.is_linked && selectRightRow(element)"
                [ngStyle]="{
                  'background-color':
                    getLinkedColor(element, 'right') || 'transparent'
                }"
              >
                {{
                  (element.modified | date : "dd LLLL yyyy HH:mm") ?? "no data"
                }}
              </td>
            </ng-container>

            <tr
              mat-header-row
              *matHeaderRowDef="[
                'expandIcon',
                'status',
                'customername',
                'id',
                'external_system_id',
                'created',
                'modified'
              ]"
            ></tr>
            <tr
              mat-row
              *matRowDef="
                let element;
                columns: [
                  'expandIcon',
                  'status',
                  'customername',
                  'id',
                  'external_system_id',
                  'created',
                  'modified'
                ]
              "
            ></tr>
            <tr
              mat-row
              *matRowDef="let row; columns: ['expandedDetail']"
              class="example-detail-row"
            ></tr>
          </table>

          <mat-paginator
            [length]="totalRecordsDrayos"
            [pageSize]="pageSizeDrayos"
            [pageSizeOptions]="[5, 10, 25, 50]"
            (page)="pageChangedDrayos($event)"
          >
          </mat-paginator>
        </div>
      </div>
    </div>
  </div>
</div>
