<div class="modal-header">
  <h3 class="modal-title">CREATE LOCATION</h3>
  <button (click)="bsModalRef.hide()" type="button" class="close" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

  <div *ngIf="loading">
    <ngx-spinner> </ngx-spinner>
  </div>

  <div class="modal-body" *ngIf="!loading">
    <div class="row">
      <div class="col-6">
        <form [formGroup]="createForm" autocomplete="off">
          <div class="row pb-2">
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>Name</mat-label>
              <input matInput formControlName="name" placeholder="Enter name">
            </mat-form-field>
          </div>

          <div class="row row-spacer">
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>Name2</mat-label>
              <input matInput formControlName="name2" placeholder="Enter name2">
            </mat-form-field>
          </div>

          <div class="row row-spacer">
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>Street</mat-label>
              <input [ngClass]="{
                'validator-error':
                createForm.controls['street'].invalid &&
                createForm.controls['street'].touched
              }" required matInput formControlName="street" placeholder="Enter street">
            </mat-form-field>
          </div>

          <div class="row row-spacer">
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>Street2</mat-label>
              <input matInput formControlName="street2" placeholder="Enter street2">
            </mat-form-field>
          </div>

          <div class="row row-spacer">
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>House number</mat-label>
              <input matInput formControlName="houseNumber" placeholder="Enter house number">
            </mat-form-field>
          </div>

          <div class="row row-spacer">
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>Zip</mat-label>
              <input required [ngClass]="{
                'validator-error':
                createForm.controls['zip'].invalid &&
                createForm.controls['zip'].touched
              }" matInput formControlName="zip" placeholder="Enter zip">
            </mat-form-field>
          </div>

          <div class="row row-spacer">
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>City</mat-label>
              <input required [ngClass]="{
                'validator-error':
                createForm.controls['city'].invalid &&
                createForm.controls['city'].touched
              }" matInput formControlName="city" placeholder="Enter city">
            </mat-form-field>
          </div>

          <div class="row row-spacer">
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>Country code</mat-label>
              <input matInput formControlName="countryCode" placeholder="Enter country code">
            </mat-form-field>
          </div>

          <div class="row row-spacer">
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>Country</mat-label>
              <input matInput formControlName="country" placeholder="Enter country">
            </mat-form-field>
          </div>

          <div class="row row-spacer">
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>Latitude</mat-label>
              <input
              [ngClass]="{
                'validator-error':
                createForm.controls['lat'].invalid &&
                createForm.controls['lat'].touched
              }"
              matInput required formControlName="lat" placeholder="Enter latitude" (ngModelChange)="latLngChange()">
            </mat-form-field>
          </div>

          <div class="row row-spacer">
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>Longtitude</mat-label>
              <input
              [ngClass]="{
                'validator-error':
                createForm.controls['lng'].invalid &&
                  createForm.controls['lng'].touched
              }"
              matInput required formControlName="lng" placeholder="Enter longtitude" (ngModelChange)="latLngChange()">
            </mat-form-field>
          </div>

          <div class="row row-spacer">
            <mat-form-field class="custom-mat" appearance="outline">
              <mat-label>Type </mat-label>
              <mat-select formControlName="locationType" style="height: 30px;" required>
                <mat-option *ngFor="let locationType of locationTypes" [value]="locationType.type">
                  {{locationType.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </form>
      </div>
      <div class="col-6">
        <mat-form-field class="example-full-width" appearance="outline">
        <mat-label>Search</mat-label>
        <input
          type="text"
          placeholder="Search for location"
          matInput
          [formControl]="searchControl"
          [matAutocomplete]="auto"
          #search
        />
        <mat-autocomplete
          #auto="matAutocomplete"
          autoActiveFirstOption
          [displayWith]="displayFnAddress"
          (optionSelected)="selectingAutocompleteAddress($event)"
        >
          <mat-option class="pl-1" *ngFor="let option of displayedSearchInputs" [value]="option">
            <span>
              <b *ngIf="option.name">{{option?.name}},</b>
              {{option.street_name}} {{option.number}} {{option.city_name}} {{option.zip}} {{option.country_code}}
            </span>
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <div id="mapToggleContainer">
        <button id="mapToggle" (click)="toggleMapType()">{{setMapTypeToggleName}}</button>
      </div>
        <agm-map
          *ngIf="mapPosition"
          [zoom]="mapPosition.zoom"
          [latitude]="mapPosition.lat"
          [longitude]="mapPosition.lng"
          [draggable]="true"
          [mapTypeId]="mapType"
          (mapClick)="mapClickedMarker($event)"
        >
          <agm-marker
            *ngIf="marker"
            [markerDraggable]="true"
            [latitude]="marker.lat"
            [longitude]="marker.lng"
            (dragEnd)="markerDragEnd($event)"
          >
          </agm-marker
          >
        </agm-map>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="row row-spacer">
      <div class="col-12 d-flex">
        <button (click)="bsModalRef.hide()" class="btn btn-danger" style="margin-right:10px !important;">
          Cancel
        </button>
        <button
          class="btn btn-ancotrans"
          [disabled]="createForm.invalid"
          (click)="createLocation()"
        >
          Create
        </button>
      </div>
    </div>
  </div>
