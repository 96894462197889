<div class="modal-header">
  <h3 class="modal-title">
    <span *ngIf="containerNumber">Edit</span
    ><span *ngIf="!containerNumber">Create</span> container
  </h3>

  <button
    (click)="bsModalRef.hide()"
    type="button"
    class="close"
    aria-label="Close"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div class="row">
    <div class="col-12">
      <form [formGroup]="createForm" autocomplete="off">
        <div class="row">
          <mat-form-field class="col-12" appearance="outline">
            <mat-label>Container number</mat-label>
            <input
              autocomplete="nope"
              matInput
              formControlName="containerNumber"
              (blur)="formatText()"
            />
          </mat-form-field>
        </div>
        <div
        class="warning-text col-12 row pb-2" style="margin: 0px;
        margin-bottom: 10px;"
        *ngIf="createForm.controls['containerNumber'].dirty && !isValidIsoContainerNumber"
      >
        Container number is not a valid ISO 6346 container number
      </div>
      <div
      class="error-text col-12 row pb-2" style="margin: 0px;
      margin-bottom: 10px;"
      *ngIf="createForm.controls['containerNumber'].dirty && isContainerAlreadyCreated"
    >
      Container number is already created.
    </div>
        <div class="row">
          <mat-form-field class="col-12" appearance="outline">
            <mat-label>Container type</mat-label>
            <input
              autocomplete="nope"
              matInput
              formControlName="containerType"
            />
          </mat-form-field>
        </div>

        <div class="row">
          <mat-form-field class="col-12" appearance="outline">
            <mat-label>Container length</mat-label>
            <input
              autocomplete="nope"
              matInput
              formControlName="containerLength"
            />
          </mat-form-field>
        </div>

        <div class="row">
          <mat-form-field class="col-12" appearance="outline">
            <mat-label>Container height</mat-label>
            <input
              autocomplete="nope"
              matInput
              formControlName="containerHeight"
            />
          </mat-form-field>
        </div>

        <div class="row">
          <mat-form-field class="col-12" appearance="outline">
            <mat-label>Tare weight</mat-label>
            <input
              autocomplete="nope"
              matInput
              formControlName="tareWeightKg"
            />
          </mat-form-field>
        </div>

        <div class="row">
          <mat-form-field class="col-12" appearance="outline">
            <mat-label>Max payload</mat-label>
            <input
              autocomplete="nope"
              matInput
              formControlName="maxPayloadKg"
            />
          </mat-form-field>
        </div>

        <div class="row">
          <mat-form-field class="col-12" appearance="outline">
            <mat-label>Cubic capacity</mat-label>
            <input
              autocomplete="nope"
              matInput
              formControlName="cubicCapcityM3"
            />
          </mat-form-field>
        </div>

        <div class="row">
          <mat-form-field class="col-12" appearance="outline">
            <mat-label>Data source</mat-label>
            <input autocomplete="nope" matInput formControlName="dataSource" />
          </mat-form-field>
        </div>

        <div class="row">
          <mat-form-field class="col-12" appearance="outline">
            <mat-label>Owner code</mat-label>
            <input autocomplete="nope" matInput formControlName="ownerCode" />
          </mat-form-field>
        </div>

        <div class="row">
          <mat-form-field class="col-12"appearance="outline">
            <mat-label>Owner company</mat-label>
            <input
              autocomplete="nope"
              matInput
              formControlName="ownerCompany"
            />
          </mat-form-field>
        </div>

        <div class="row">
          <mat-form-field class="col-12" appearance="outline">
            <mat-label>Owner city</mat-label>
            <input autocomplete="nope" matInput formControlName="ownerCity" />
          </mat-form-field>
        </div>

        <div class="row">
          <mat-form-field class="col-12" appearance="outline">
            <mat-label>Country</mat-label>
            <mat-select formControlName="ownerCountry" style="height: 30px;">
              <mat-option *ngFor="let country of countries" [value]="country.code">
                {{ country.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </form>
    </div>
  </div>
</div>

<div class="modal-footer">
  <div class="row row-spacer">
    <div class="col-12 d-flex">
      <button
        (click)="bsModalRef.hide()"
        class="btn btn-danger"
        style="margin-right: 10px !important"
      >
        Cancel
      </button>
      <button
        *ngIf="containerNumber"
        class="full-width btn btn-ancotrans"
        [disabled]="
          createForm.invalid || createForm.get('ownerCountry')?.value == 'NONE'
        "
        (click)="createContainer()"
      >
        Edit container
      </button>
      <button
        *ngIf="!containerNumber"
        class="full-width btn btn-ancotrans"
        [disabled]="
          createForm.invalid || createForm.get('ownerCountry')?.value == 'NONE'
        "
        (click)="createContainer()"
      >
        Create container
      </button>
    </div>
  </div>
</div>
