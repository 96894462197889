import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Auth } from 'aws-amplify';
import { Observable } from 'rxjs';
import { runtimeEnvironment } from 'src/environments/environment.docker';
import {
  UserListItems,
  UserModelLegacy,
} from '../models/system-user.interface';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private httpClient: HttpClient;
  token: string;
  endpoint = runtimeEnvironment.cognitoApiUrl;
  endpointUserPart = '/cognito/users/';
  campaignEndpoint = runtimeEnvironment.cognitoApiUrl + '/activecampaign';
  constructor(handler: HttpBackend) {
    this.httpClient = new HttpClient(handler);
    Auth.currentAuthenticatedUser().then((user) => {
      this.token = user.signInUserSession.idToken.jwtToken;
    });
  }

  getUsersList(filter: string): Observable<UserListItems> {
    return this.httpClient.get<UserListItems>(
      `${this.endpoint}${this.endpointUserPart}` + '?filter=' + filter,
      {
        headers: {
          Authorization: 'Bearer ' + this.token,
        },
      }
    );
  }

  deleteUser(username: string): Observable<boolean> {
    return this.httpClient.delete<boolean>(
      `${this.endpoint}${this.endpointUserPart}${username}`,
      {
        headers: {
          Authorization: 'Bearer ' + this.token,
        },
      }
    );
  }

  updateUserAccess(username: string, action: string): Observable<boolean> {
    return this.httpClient.post<boolean>(
      `${this.endpoint}${this.endpointUserPart}${username}/${action}`,
      '',
      {
        headers: {
          Authorization: 'Bearer ' + this.token,
        },
      }
    );
  }

  getUserLegacyPermissions(username: string): Observable<UserModelLegacy> {
    return this.httpClient.get<UserModelLegacy>(
      `${this.endpoint}${this.endpointUserPart}${username}/legacywebbookingpermissions`,
      {
        headers: {
          Authorization: 'Bearer ' + this.token,
        },
      }
    );
  }

  getUserGroups(username: string): Observable<any> {
    return this.httpClient.get<any>(
      `${this.endpoint}${this.endpointUserPart}${username}/groups`,
      {
        headers: {
          Authorization: 'Bearer ' + this.token,
        },
      }
    );
  }

  addUserGroup(username: string, groupName: string): Observable<any> {
    return this.httpClient.post<boolean>(
      `${this.endpoint}${this.endpointUserPart}${username}/groups/${groupName}`,
      '',
      {
        headers: {
          Authorization: 'Bearer ' + this.token,
        },
      }
    );
  }

  deleteUserGroup(username: string, groupName: string): Observable<any> {
    return this.httpClient.delete<boolean>(
      `${this.endpoint}${this.endpointUserPart}${username}/groups/${groupName}`,
      {
        headers: {
          Authorization: 'Bearer ' + this.token,
        },
      }
    );
  }

  addNewUser(username: any) {
    return this.httpClient.post<boolean>(
      `${this.endpoint}${this.endpointUserPart}`,
      username,
      {
        headers: {
          Authorization: 'Bearer ' + this.token,
        },
      }
    );
  }

  disableActiveCampaignUser(emailToDisable: string): Observable<any> {
    return this.httpClient.put<any>(`${this.campaignEndpoint}user`, {email: emailToDisable}, {
      headers: {
        Authorization: 'Bearer ' + this.token,
      },
    });
  }

  resendTemporarypasswordPassword(userId: string): Observable<any> {
    let body = {
      userId: userId
    }
    return this.httpClient.put<any>(`${this.endpoint}${this.endpointUserPart}${userId}/resettemporarypassword`, body, {
      headers: {
        Authorization: 'Bearer ' + this.token,
      },
    });
  }

  changeEmail(userId: string, newEmail: string): Observable<any> {
    let body = {
      newEmail: newEmail
    }
    return this.httpClient.put<any>(`${this.endpoint}${this.endpointUserPart}${userId}/changeemail`, body, {
      headers: {
        Authorization: 'Bearer ' + this.token,
      },
    });
  }
}
