declare var ENV: {
  userPoolWebClientId: string;
  userPoolId: string;
  awsCognitoIdentityPoolId: string;
  geodataUrl: string;
  containerUrl: string;
  docDigitizerUrl: string;
  cognitoApiUrl: string;
  identityServiceApiUrl: string;
};

// hardcoded values for local-env, every stack re-deploy, cognito values need to be updated

export const runtimeEnvironment = {
  production: false,
  userPoolId:
    ENV.userPoolId === '${userPoolId}'
      ? 'eu-central-1_JEwtMflxw'
      : ENV.userPoolId,
  userPoolWebClientId:
    ENV.userPoolWebClientId === '${userPoolWebClientId}'
      ? '3e33cmio4pod55eummgsdjurj6'
      : ENV.userPoolWebClientId,
  awsCognitoIdentityPoolId:
    ENV.awsCognitoIdentityPoolId === '${awsCognitoIdentityPoolId}'
      ? false
      : ENV.awsCognitoIdentityPoolId,
  geodataUrl:
    ENV.geodataUrl === '${geodataUrl}'
      ? 'https://geodata.test.ancotrans.net'
      : ENV.geodataUrl,
  containerUrl:
    ENV.containerUrl === '${containerUrl}'
      ? 'https://containerapi.test.ancotrans.net'
      : ENV.containerUrl,
  cognitoApiUrl:
    ENV.cognitoApiUrl === '${cognitoApiUrl}'
      ? 'https://api.webbooking.test.ancotrans.net'
      : ENV.cognitoApiUrl,
  identityServiceApiUrl: ENV.identityServiceApiUrl === '${identityServiceApiUrl}' ? 'https://test-identity.ancotrans.com/api' : ENV.identityServiceApiUrl,
  disableBackend: true,
};
