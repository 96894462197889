<div class="modal-header" >
    <h3 class="modal-title">{{title}}</h3>
</div>

    <div class="modal-body" [formGroup]="createForm">
        <div class="row">
            <div class="col-12 d-grid">
                <mat-form-field  class="w-100" appearance="outline">
                    <mat-label>Email</mat-label>
                    <input type="text" matInput formControlName="email" name="email" required />
                  </mat-form-field>
            </div>
        </div>
    </div>

<div class="modal-footer">
    <div class="row row-spacer">
        <div class="col-12 d-flex">
          <button (click)="bsModalRef.hide()" class="btn btn-danger" style="margin-right:10px !important;">
            Cancel
          </button>
          <button *ngIf="!userId" [disabled]="!createForm.valid" (click)="createUser()" class="btn btn-ancotrans" style="margin-right:10px !important;">
            Create
          </button>
          <button *ngIf="userId" [disabled]="!createForm.valid" (click)="changeEmail()" class="btn btn-ancotrans" style="margin-right:10px !important;">
            Change
          </button>
        </div>
      </div>
</div>
