import { Component, Input } from '@angular/core';

@Component({
  selector: 'avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
})
export class AvatarComponent {
  @Input() set username(value: string | undefined) {
    if (value) {
      this.usernameValue = value;
      this.generateInitials();

      this.ancotransUsernameFormat.test(this.usernameValue)
        ? (this.avatarColor = '#004C40')
        : this.generateAvatarColor();
    }
  }

  @Input() dimensionCircle: number = 30
  @Input() fontSize: number = 13

  usernameValue: string = '';
  ancotransUsernameFormat = /[!-\/:-@[-`{-~]/;
  userInitials: string = '';
  avatarColor: string;
  private colors = [
    '#513E92',
    '#127859',
    '#0F2E25',
    '#6FD1B4',
    '#42174B',
    '#1B0957',
    '#012119',
    '#414169',
    '#2A9277',
    '#17474B',
    '#093557',
    '#010562',
    '#31274B',
    '#08920E',
    '#FFD358',
    '#095735',
    '#BBBCC8',
    '#6C9BF2',
    '#4A363C',
    '#41111B',
    '#14412D',
    '#A9E3DE',
    '#21010D',
    '#FFD9D9',
    '#468547',
    '#E08CA9',
    '#EB3B78',
    '#EB3B78',
    '#EB3B78',
    '#44444B',
    '#E08CD1',
    '#C8EE7F',
    '#8B9F65',
    '#BEA252',
    '#74474B',
    '#D58CE0',
    '#926F0A',
    '#0A9292',
    '#70F0F0',
    '#41274B',
    '#9E8CE0',
    '#65D5D5',
    '#AFE7E7',
    '#E38976',
    '#47474B',
    '#7535AC',
    '#DA38E1',
    '#443246',
    '#368B71',
    '#EB2322',
  ];

  private generateInitials(): void {
    if (this.ancotransUsernameFormat.test(this.usernameValue)) {
      this.userInitials = this.usernameValue
        ?.toUpperCase()
        ?.split('/')
        ?.pop()
        ?.split('@')[0]!;
    } else {
      this.userInitials = this.usernameValue
        .toUpperCase()
        .split(' ')
        .slice(0, 2)
        .map((x) => x[0])
        .reduce((x, y) => x + y);
    }
  }

  private generateAvatarColor(): void {
    const colorIndex = Math.pow(this.usernameValue.length, 3) % 50;
    this.avatarColor = this.colors[colorIndex];
  }
}
