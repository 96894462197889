<div class="modal-header" *ngIf="data" >
    <h3 class="modal-title">{{title}}</h3>
</div>

    <div class="modal-body">
        <div class="row">
            <div class="col-12" style="overflow-wrap: anywhere;">
                {{data}}
            </div>
        </div>
    </div>

<div class="modal-footer">
    <div class="row row-spacer">
        <div class="col-12 d-flex">
          <button (click)="bsModalRef.hide()" class="btn btn-danger" style="margin-right:10px !important;">
            Exit
          </button>
          <button *ngIf="showContinueButton" (click)="continue()" class="btn btn-success" style="margin-right:10px !important;">
            Continoue
          </button>
        </div>
      </div>
</div>
