import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Auth } from 'aws-amplify';
import { Observable } from 'rxjs';
import { CognitoGroups } from '../enums/users/cognito-groups.enum';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class CognitoAdminGuard implements CanActivate {
  constructor(
    public readonly router: Router,
    public readonly authService: AuthService
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return Auth.currentAuthenticatedUser({ bypassCache: true })
      .then((user) => {
        if (user) {
          if (this.authService.hasGroup(CognitoGroups.cognitoadmin)) {
            this.authService.hideMenus = false;
            return true;
          } else {
            this.router.navigate(['insufficient-permissions']);
            return false;
          }
        } else {
          this.authService.hideMenus = true;
          this.router.navigate(['/auth']);
          return false;
        }
      })
      .catch(() => {
        this.authService.hideMenus = true;
        this.router.navigate(['/auth']);
        return false;
      });
  }
}
