import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { ContainerService } from 'src/app/services/container.service';

@Component({
  selector: 'app-container-delete-modal',
  templateUrl: './container-delete-modal.component.html',
  styleUrls: ['./container-delete-modal.component.scss']
})
export class ContainerDeleteModalComponent implements OnInit {
  @Input() containerNo: string;
  @Output() closed: EventEmitter<never> = new EventEmitter<never>();

  constructor(public bsModalRef: BsModalRef, private containerService: ContainerService, private toastr: ToastrService) { }

  ngOnInit(): void {
  }

  deleteContainer(): void {
    this.containerService.removeContainer(this.containerNo).subscribe(
      (response) => {
        this.toastr.success('Container has been deleted', 'Success');
        this.closed.emit();
      },
      () => {
        this.toastr.error('Container has not been deleted', 'Failure');
      }
    );
  }
}


