import { MapsAPILoader } from '@agm/core';
import {
  Component,
  ElementRef,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { LocationService } from 'src/app/services/location.service';
import { LocationTypeInterface } from '../../../models/location-type.interface';
import { MapMarker } from '../../../models/map-marker.interface';
import { MapPosition } from '../../../models/map-position.interface';
import { LocationFormFields } from '../edit-location/edit-location.component';
import { debounceTime, map, takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ApiAddressAutocomplete } from 'src/app/models/location.interface';
import { PlaceType2 } from 'src/app/models/google-api.interface';
import { runtimeEnvironment } from 'src/environments/environment.docker';

declare var $: any;
declare const google: any;

@Component({
  templateUrl: './create-location.component.html',
  styleUrls: ['./create-location.component.scss'],
})
export class CreateLocationComponent implements OnInit, OnDestroy {

  private _locationTypes: LocationTypeInterface[] = [];

  @Input() get locationTypes(): LocationTypeInterface[] {
    // Filter out items where type is not 'all'
    return this._locationTypes.filter(item => item.type !== 'ALL');
  }

  set locationTypes(value: LocationTypeInterface[]) {
    this._locationTypes = value;
  }

  @ViewChild('search')
  public searchElementRef: ElementRef | undefined;

  loading: boolean = false;
  mapPosition: MapPosition | undefined;
  marker: MapMarker | undefined;
  public searchControl: FormControl = new FormControl();
  public displayedSearchInputs: any[] = [];

  destroy$ = new Subject();

  formFields = LocationFormFields;
  createForm: FormGroup;

  toolTip =
    'Use a marker to mark a location to complete the value for that field.';

  mapType = 'roadmap';
  get setMapTypeToggleName(): string {
    return this.mapType === 'roadmap' ? 'Satellite' : 'Roadmap';
  }

  constructor(
    private ngZone: NgZone,
    private mapsAPILoader: MapsAPILoader,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    public route: ActivatedRoute,
    private fb: FormBuilder,
    private locationService: LocationService,
    public bsModalRef: BsModalRef
  ) {
    this.initGoogleMaps();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.initForm();

    this.searchControl.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        debounceTime(1000),
        tap((x) => (x.length < 3 ? this.searchControl.setValue('') : ''))
      )
      .subscribe((value) => {
        value?.length >= 3
          ? this.searchAddressInGoogle()
          : (this.displayedSearchInputs = []);
      });
  }

  initForm(): void {
    this.createForm = this.fb.group({
      [this.formFields.name]: [''],
      [this.formFields.name2]: [''],
      [this.formFields.street]: ['', [Validators.required]],
      [this.formFields.street2]: [''],
      [this.formFields.houseNumber]: [''],
      [this.formFields.zip]: ['', [Validators.required]],
      [this.formFields.city]: ['', [Validators.required]],
      [this.formFields.countryCode]: [
        {
          value: '',
          disabled: true,
        },
        [Validators.required],
      ],
      [this.formFields.country]: [
        {
          value: '',
          disabled: true,
        },
        [Validators.required],
      ],
      [this.formFields.lng]: ['', [Validators.required]],
      [this.formFields.lat]: ['', [Validators.required]],
      [this.formFields.locationType]: ['ADDRESS', [Validators.required]],
    });
  }

  displayFnAddress(address: ApiAddressAutocomplete): any {
    if (typeof address === 'string') {
      return address;
    }
    return address
      ? `${address.street_name ?? ''} ${address.number ?? ''}  ${
          address.city_name ?? ''
        } ${address.zip ?? ''}  ${address?.country_code ?? ''}`
      : '';
  }

  selectingAutocompleteAddress(event: any): void {
    this.mapClickedMarker(
      event,
      event.option.value.latitude,
      event.option.value.longitude
    );
    this.mapPosition = {
      lat: event.option.value.latitude,
      lng: event.option.value.longitude,
      zoom: 16,
    };
  }

  searchAddressInGoogle() {
    this.locationService.searchForAddress(this.searchControl.value).subscribe(
      (response: any) => {
        this.displayedSearchInputs = response.map((x: any) => ({
          total_score: x?.address?.rating,
          house_no_id: x?.address?.place_id,
          name: x?.address?.formatted_address?.includes(x?.address?.name!)
            ? ''
            : x?.address?.name?.slice(0, 50),
          name2: x?.address?.name?.slice(0, 50),
          street_name: x?.address?.address_components?.filter((param: any) =>
            param.types.includes(PlaceType2.route)
          )[0]?.long_name,
          number: x?.address?.address_components?.filter((param: any) =>
            param.types.includes(PlaceType2.street_number)
          )[0]?.long_name,
          zip: x?.address?.address_components?.filter((param: any) =>
            param.types.includes(PlaceType2.postal_code)
          )[0]?.long_name,
          city_name: x?.address?.address_components?.filter(
            (param: any) =>
              param.types.includes(PlaceType2.postal_town) ||
              param.types.includes(PlaceType2.locality)
          )[0]?.long_name,
          country_code: x?.address?.address_components?.filter((param: any) =>
            param.types.includes(PlaceType2.country)
          )[0]?.short_name,
          latitude: x?.address?.geometry?.location?.lat,
          longitude: x?.address?.geometry?.location?.lng,
          address_type: 'google',
        }));
      },
      (err: any) => console.log(err)
    );
  }

  toggleSpinner(show: boolean) {
    this.loading = show;
    if (show) {
      this.spinner.show();
    } else {
      this.spinner.hide();
    }
  }

  toggleMapType() {
    this.mapType = this.mapType === 'roadmap' ? 'hybrid' : 'roadmap';
  }

  markerDragEnd(event: any) {
    if (!this.marker) {
      return;
    }

    let lat = event['coords'].lat;
    let lng = event['coords'].lng;
    this.marker = {
      lat: lat,
      lng: lng,
    };
    this.createForm.controls['lat'].patchValue(lat);
    this.createForm.controls['lng'].patchValue(lng);

    this.setAddress(lat, lng);
  }

  mapClickedMarker(
    event: any,
    autocompleteLat?: number,
    autocompleteLng?: number
  ) {
    let lat = autocompleteLat ? autocompleteLat : event['coords'].lat;
    let lng = autocompleteLng ? autocompleteLng : event['coords'].lng;

    this.marker = {
      lat: lat,
      lng: lng,
    };

    this.createForm.controls['lat'].patchValue(lat);
    this.createForm.controls['lng'].patchValue(lng);

    this.setAddress(lat, lng);
  }

  private setAddress(lat: any, lng: any) {
    let geocoder = new google.maps.Geocoder();
    let latlng = new google.maps.LatLng(lat, lng);
    this.createForm.controls['houseNumber'].patchValue('');
    this.createForm.controls['street'].patchValue('');
    this.createForm.controls['zip'].patchValue('');
    this.createForm.controls['city'].patchValue('');

    let self = this;
    geocoder.geocode({ latLng: latlng }, (results: any, status: any) => {
      if (results.length == 0) {
        return;
      }

      // only take first result
      for (let component of results[0].address_components) {
        for (let componentType of component.types) {
          if (componentType == 'street_number') {
            self.createForm.controls['houseNumber'].patchValue(
              component.long_name
            );
          } else if (componentType == 'route') {
            self.createForm.controls['street'].patchValue(component.long_name);
          } else if (componentType == 'postal_code') {
            self.createForm.controls['zip'].patchValue(component.long_name);
          } else if (componentType == 'locality') {
            self.createForm.controls['city'].patchValue(component.long_name);
          } else if (componentType == 'country') {
            self.createForm.controls['countryCode'].patchValue(
              component.short_name
            );
            self.createForm.controls['country'].patchValue(component.long_name);
          }
        }
      }
    });
  }

  private initGoogleMaps() {
    //set current position
    this.mapPosition = {
      lat: 55,
      lng: 11,
      zoom: 4.8,
    } as MapPosition;
    //load Places Autocomplete
    this.mapsAPILoader.load().then(() => {
      this.loadContentOfMap();
    });
  }

  private loadContentOfMap() {
    setTimeout(() => {
      if (!this.searchElementRef) {
        this.loadContentOfMap();
      } else {
        let autocomplete = new google.maps.places.Autocomplete(
          this.searchElementRef.nativeElement,
          {
            types: ['address'],
          }
        );
        autocomplete.addListener('place_changed', () => {
          this.ngZone.run(() => {
            //get the place result
            let place: google.maps.places.PlaceResult = autocomplete.getPlace();
            //verify result
            if (place.geometry === undefined || place.geometry === null) {
              return;
            }

            //set latitude, longitude and zoom on map
            this.mapPosition = {
              lat: place.geometry.location.lat(),
              lng: place.geometry.location.lng(),
              zoom: 12,
            };

            this.marker = {
              lat: this.mapPosition.lat,
              lng: this.mapPosition.lng,
            };
            this.createForm.controls['lat'].patchValue(this.mapPosition.lat);
            this.createForm.controls['lng'].patchValue(this.mapPosition.lng);
            this.setAddress(this.mapPosition.lat, this.mapPosition.lng);
          });
        });
      }
    }, 250);
  }

  latLngChange() {
    this.marker = {
      lat: this.createForm.get('lat')?.value,
      lng: this.createForm.get('lng')?.value,
    };
  }

  createLocation() {
    const payload = {
      address: {
        name: this.createForm.controls[this.formFields.name].value,
        name2: this.createForm.controls[this.formFields.name2].value,
        street: this.createForm.controls[this.formFields.street].value,
        street2: this.createForm.controls[this.formFields.street2].value,
        house_no: this.createForm.controls[this.formFields.houseNumber].value,
        zip: this.createForm.controls[this.formFields.zip].value,
        city: this.createForm.controls[this.formFields.city].value,
        country: {
          code: this.createForm.controls[this.formFields.countryCode].value,
          country: this.createForm.controls[this.formFields.country].value,
        },
        geo_decimal: {
          lat: this.createForm.controls[this.formFields.lat].value,
          lng: this.createForm.controls[this.formFields.lng].value,
        },
      },
      location: {
        type: this.createForm.controls[this.formFields.locationType].value,
      },
    };

    this.locationService.createLocation(payload).subscribe(
      (data) => {
        this.toggleSpinner(false);
        this.toastr.success('Location has been created', 'Success');
        this.bsModalRef.hide();
      },
      (err) => {
        this.toggleSpinner(false);
        this.toastr.error('Location has not been created', 'Failure');
      }
    );
  }
}
