import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

@Component({
  selector: 'paging',
  templateUrl: './paging.directive.html',
  styleUrls: ['./paging.directive.scss'],
})
export class PagingDirective implements OnInit {
  itemsLengthValue: number = 0;
  @Input() totalCountShow = true;
  @Input() offset: number = 0;
  @Input() limit: number = 10;
  @Input()
  set itemsLength(itemsLength: number | undefined) {
    if (this.itemsLengthValue == itemsLength) {
      return;
    }
    this.itemsLengthValue = itemsLength ? itemsLength : 0;
    if (this.itemsLengthValue > 0) {
      this.changePage(1);
    }
  }
  @Output() pageChanged = new EventEmitter<any>();

  currentPage: number = 1;
  numberOfPages: number = 1;
  pages: number[] = [];

  constructor() {}

  ngOnInit(): void {
    this.calculatePages();
    this.currentPage = 1;
  }

  changePage(pageNumber: number): void {
    this.offset = this.limit * (pageNumber - 1);
    this.currentPage = pageNumber;
    this.calculatePages();
    this.pageChanged.emit({ offset: this.offset });
  }

  previousPage() {
    if (this.currentPage > 1) {
      this.changePage(this.currentPage - 1);
    }
  }

  nextPage() {
    if (this.currentPage < this.numberOfPages) {
      this.changePage(this.currentPage + 1);
    }
  }

  private calculatePages() {
    if (!this.itemsLengthValue) {
      return;
    }
    this.numberOfPages = Math.ceil(this.itemsLengthValue / this.limit);
    this.pages = [];
    for (let i = 0; i < this.numberOfPages; i++) {
      if (this.currentPage - 2 <= i + 1 && this.currentPage + 2 >= i + 1) {
        this.pages.push(i + 1);
      }
    }
  }
}
