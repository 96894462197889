import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { LocationInterface } from 'src/app/models/location.interface';
import { LocationService } from 'src/app/services/location.service';

@Component({
  selector: 'app-delete-location-modal',
  templateUrl: './delete-location-modal.component.html',
  styleUrls: ['./delete-location-modal.component.scss']
})
export class DeleteLocationModalComponent implements OnInit {

  @Input() selectedLocation: LocationInterface;
  @Output() closed: EventEmitter<never> = new EventEmitter<never>();

  constructor(public bsModalRef: BsModalRef, private locationService: LocationService, private toastr: ToastrService) { }

  ngOnInit(): void {}

  deleteLocation(): void {
      if (this.selectedLocation.extras.is_candidate) {
        this.locationService
          .locationRejectCandidate(this.selectedLocation.extras.geodata_id)
          .subscribe(
            (response) => {
              this.locationRemoveSuccess('candidate ');
            },
            () => {
              this.locationRemoveFail('candidate ');
            }
          );
      } else {
        this.locationService
          .removeLocation(this.selectedLocation.extras.geodata_id)
          .subscribe(
            (response) => {
              this.locationRemoveSuccess();
            },
            () => {
              this.locationRemoveFail();
            }
          );
      }
    }

    locationRemoveSuccess(val?: string): void {
      this.toastr.success(`Location ${val ?? ''}has been deleted`, 'Success');
      this.closed.emit();
    }

    locationRemoveFail(val?: string): void {
      this.toastr.error(`Location ${val ?? ''}has not been deleted`, 'Failure');
    }
}
