import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { Amplify } from 'aws-amplify';
import { AppModule } from './app/app.module';
import { runtimeEnvironment } from './environments/environment.docker';

if (runtimeEnvironment.production) {
  enableProdMode();
}

Amplify.configure({
  Auth: runtimeEnvironment,
});

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
