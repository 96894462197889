import { Injectable } from "@angular/core";

@Injectable()
export class DateService {

  constructor() {

  }

  getDateFormatWithoutTime(date: Date) {
    if (!date) {
      return '';
    }

    var month = ('0' + (date.getMonth() + 1)).slice(-2);
    var day = ('0' + date.getDate()).slice(-2);
    var year = date.getFullYear();
    return year + '-' + month + '-' + day;
  }
}
