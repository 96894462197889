<div class="text-center">
	<div>
		<h1><i class="bi bi-shield-exclamation"></i></h1>
	</div>
	<div class="mt-3">
		<h2>{{ message }}</h2>
	</div>
	<div>
		<h4>Request access from your supervisor</h4>
        <h5><h1><i class="bi bi-key"></i> + <i class="bi bi-lock"></i> = <i class="bi bi-emoji-laughing"></i></h1></h5>
	</div>
</div>
