import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from './auth/auth.service';
import { MatSidenav } from '@angular/material/sidenav';
import { BreakpointObserver } from '@angular/cdk/layout';
import { NavigationEnd, Router } from '@angular/router';
import { delay, filter, takeUntil } from 'rxjs/operators';
import { Auth } from 'aws-amplify';
import { CognitoGroups } from './enums/users/cognito-groups.enum';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterViewInit, OnInit {
  @ViewChild(MatSidenav)
  sidenav!: MatSidenav;
  public version: string =  require('../../package.json').version;
  noAccess = 'You do not have access to this part of the system';
  isLoggedIn: boolean;
  user:
    | { id: string | null; username: string | null; email: string | null }
    | undefined;

  get isCognitoAdmin(): boolean {
    return this.authService.hasGroup(CognitoGroups.cognitoadmin);
  }

  constructor(private observer: BreakpointObserver, private router: Router, public authService: AuthService) {}

  public ngOnInit(): void {
    this.authService.isLoggedIn$.subscribe(
      (isLoggedIn) => (this.isLoggedIn = isLoggedIn)
    );

    this.authService.auth$.subscribe(({ id, username, email }) => {
      this.user = { id, username, email };
    });
  }

  ngAfterViewInit() {
      this.observer
      .observe(['(max-width: 800px)'])
      .pipe(delay(1))
      .subscribe((res: any) => {
        console.log(res)
        if (res.matches) {
          this.sidenav.mode = 'over';
          this.sidenav.close();
        } else {
          this.sidenav.mode = 'side';
          this.sidenav.open();
        }
      });

    this.router.events
      .pipe(
        filter((e) => e instanceof NavigationEnd)
      )
      .subscribe(() => {
        if (this.sidenav.mode === 'over') {
          this.sidenav.close();
        }
      });

  }

  public signOut(): void {
   this.authService.signOut();
  }
}
