<div class="modal-header" *ngIf="data">
  <h3 class="modal-title">{{ title }}</h3>
</div>

<div class="modal-body d-flex">
  <mat-accordion style="width: 100%">
    <mat-expansion-panel
      (opened)="panelOpenState = true"
      (closed)="panelOpenState = false"
    >
      <mat-expansion-panel-header>
        <mat-panel-title> Web booking </mat-panel-title>
      </mat-expansion-panel-header>

      <div class="row" style="min-width: 100%">
        <div class="col-12" style="overflow-wrap: anywhere">
          <table
            *ngIf="groups"
            mat-table
            [dataSource]="groups"
            class="mat-elevation-z8"
            style="min-width: 100%"
          >
            <!--- Note that these columns can be defined in any order.
                          The actual rendered columns are set as a property on the row definition" -->

            <!-- Position Column -->
            <ng-container matColumnDef="GroupName">
              <th mat-header-cell *matHeaderCellDef>Group name</th>
              <td mat-cell *matCellDef="let element">
                {{ element.GroupName }}
              </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="LastModifiedDate">
              <th mat-header-cell *matHeaderCellDef>Last modification date</th>
              <td mat-cell *matCellDef="let element">
                {{ element.LastModifiedDate | date: "medium" }}
              </td>
            </ng-container>

            <ng-container matColumnDef="actions">
              <th
                mat-header-cell
                *matHeaderCellDef
                style="text-align: right; margin-right:10px"
              >
                Actions
              </th>
              <td mat-cell *matCellDef="let element">
                <button
                  style="margin-left: 5px; float: right"
                  matTooltip="Disable"
                  [matTooltipPosition]="'above'"
                  class="btn btn-warning"
                  (click)="deleteUserGroup(element)"
                >
                  <i class="fa fa-remove"></i>
                </button>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
          <h5 *ngIf="groupsToAdd.length" class="mt-3">Available groups to add:</h5>

          <button
            *ngFor="let item of groupsToAdd"
            class="btn btn-ancotrans mt-2 mr-1"
            (click)="addUserGroup(item)"
          >
            {{ item }}
          </button>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>

<div class="modal-footer">
  <div class="row row-spacer">
    <div class="col-12 d-flex">
      <button
        (click)="bsModalRef.hide()"
        class="btn btn-danger"
        style="margin-right: 10px !important"
      >
        Exit
      </button>
    </div>
  </div>
</div>
