import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LocationComponent } from './components/location/location.component';
import { EditLocationComponent } from './components/location/edit-location/edit-location.component';
import { CreateLocationComponent } from './components/location/create-location/create-location.component';
import { ContainerComponent } from './components/container/container.component';
import { ContainerModalComponent } from './components/container/container-modal/container-modal.component';
import { AuthComponent } from './components/auth/auth.component';
import { AuthGuard } from './auth/auth.guard';
import { InsufficientPermissionsComponent } from './shared/insufficient-permissions/insufficient-permissions.component';
import { UserManagementComponent } from './components/user-management/user-management.component';
import { CognitoAdminGuard } from './auth/cognito-admin.guard';
import { EdiFactComponent } from './components/edi-fact/edi-fact.component';
import { IdentityServiceComponent } from './components/identity-service/identity-service.component';

const routes: Routes = [
  { path: 'auth', component: AuthComponent },
  { path: 'locations', component: LocationComponent, canActivate: [AuthGuard] },
  {
    path: 'locations/create',
    component: CreateLocationComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'locations/:id',
    component: EditLocationComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'containers',
    component: ContainerComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'containers/create',
    component: ContainerModalComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'user-management',
    component: UserManagementComponent,
    canActivate: [AuthGuard, CognitoAdminGuard],
  },
  {
    path: 'identity-service',
    component: IdentityServiceComponent,
    canActivate: [AuthGuard, CognitoAdminGuard],
  },
  {
    path: 'edi-fact',
    component: EdiFactComponent,
    canActivate: [AuthGuard],
  },
  { path: '**', redirectTo: '/locations', pathMatch: 'full' },
  {
    path: 'insufficient-permissions',
    component: InsufficientPermissionsComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
