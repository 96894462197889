import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { runtimeEnvironment } from 'src/environments/environment.docker';
import { AuditlogInterface } from '../models/auditlog.interface';
import { CountryInterface } from '../models/country.interface';
import { LocationTypeInterface } from '../models/location-type.interface';
import {
  LocationInterface,
  PlaceAutocompleteWithAddressResult,
  SuggestedLocationDataChange,
} from '../models/location.interface';
import { PaginationInterface } from '../models/pagination.interface';
import { Auth } from 'aws-amplify';

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  token: string;
  endpoint = runtimeEnvironment.geodataUrl;
  adminAddressPart = '/v1/admin/addresses/';
  adminValidationPart = '/v1/admin/validations/';
  awsEndpoint = runtimeEnvironment.cognitoApiUrl;
  constructor(private http: HttpClient, handler: HttpBackend, private httpBackend: HttpClient) {
    this.httpBackend = new HttpClient(handler);

    Auth.currentAuthenticatedUser().then((user) => {
      this.token = user.signInUserSession.idToken.jwtToken;
    });
  }

  searchForAddress(searchValue: string): Observable<PlaceAutocompleteWithAddressResult[]> {
    return this.httpBackend.get<PlaceAutocompleteWithAddressResult[]>(
      `${this.awsEndpoint}/googleAddress/?address=${searchValue}`,
      {
        headers: {
          Authorization: 'Bearer ' + this.token,
        },
      }
    );
  }

  getLocations(
    queryString: string
  ): Observable<PaginationInterface<LocationInterface[]>> {
    return this.http.get<PaginationInterface<LocationInterface[]>>(
      `${this.endpoint}${this.adminAddressPart}searchlocal` + queryString
    );
  }

  getLocationTypes(): Observable<LocationTypeInterface[]> {
    return this.http.get<LocationTypeInterface[]>(
      `${this.endpoint}${this.adminAddressPart}addresstypes`
    );
  }

  removeLocation(locationId: string): Observable<void> {
    return this.http.delete<void>(
      `${this.endpoint}${this.adminAddressPart}${locationId}`
    );
  }

  downloadAuditLogs(): Observable<AuditlogInterface[]> {
    return this.http.get<AuditlogInterface[]>(
      `${this.endpoint}/v1/admin/auditLogs`
    );
  }

  getCountries(): Observable<CountryInterface[]> {
    return this.http.get<CountryInterface[]>(
      `${this.endpoint}/v1/admin/countries/`
    );
  }

  updateLocation(body: any, locationId: string): Observable<LocationInterface> {
    return this.http.put<LocationInterface>(
      `${this.endpoint}${this.adminAddressPart}${locationId}`,
      body
    );
  }

  getSuggestedLocationData(
    locationId: string
  ): Observable<SuggestedLocationDataChange> {
    return this.http.get<SuggestedLocationDataChange>(
      `${this.endpoint}${this.adminAddressPart}` + locationId
    );
  }

  locationValidate(locationId: string, body: any): Observable<void> {
    return this.http.put<void>(
      `${this.endpoint}${this.adminValidationPart}${locationId}/validate`,
      body
    );
  }

  locationApproveSuggestion(locationId: string, body: any): Observable<void> {
    return this.http.put<void>(
      `${this.endpoint}${this.adminValidationPart}${locationId}/approvesuggestion`,
      body
    );
  }

  locationRejectSuggestion(locationId: string, body: any): Observable<void> {
    return this.http.put<void>(
      `${this.endpoint}${this.adminValidationPart}${locationId}/rejectsuggestion`,
      body
    );
  }

  locationRejectCandidate(locationId: string): Observable<void> {
    return this.http.put<void>(
      `${this.endpoint}${this.adminValidationPart}${locationId}/rejectCandidate`,
      null
    );
  }

  createLocation(body: any): Observable<void> {
    return this.http.post<void>(
      `${this.endpoint}${this.adminAddressPart}`,
      body
    );
  }
}
