<div class="modal-header" >
  <h3 class="modal-title">Delete container</h3>
  <button
  (click)="bsModalRef.hide()"
  type="button"
  class="close"
  aria-label="Close"
>
  <span aria-hidden="true">&times;</span>
</button>
</div>

  <div class="modal-body">
      <div class="row">
          <div class="col-12 d-grid">
            <span>You are just about to remove the container number <b>{{containerNo}}</b>, are you sure?</span>
          </div>
      </div>
  </div>

<div class="modal-footer">
  <div class="row row-spacer">
      <div class="col-12 d-flex">
        <button (click)="bsModalRef.hide()" class="btn btn-danger" style="margin-right:10px !important;">
          Exit
        </button>
        <button (click)="deleteContainer()" class="btn btn-ancotrans" style="margin-right:10px !important;">
          Delete
        </button>
      </div>
    </div>
</div>
