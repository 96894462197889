<div class="modal-header">
  <h3 class="modal-title">UPDATE LOCATION</h3>
  <button (click)="bsModalRef.hide()" type="button" class="close" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
  <div class="modal-subtitle row">
    <div class="col-3">
      <p class="label-text">Validation Status: <span class="info-text">{{data.extras.validation_state}}</span></p>
      <p class="label-text">Last Validation check: <span class="info-text d-flex">{{data.extras.last_validation_check | date:'dd LLLL yyyy HH:mm'}}</span></p>
    </div>
    <div class="col-3">
      <p class="label-text">Is Candidate: <span class="fa" [ngClass]="data.extras.is_candidate ? 'fa-check-square' : 'fa-times'"></span></p>
      <p class="label-text">Created by: <span class="info-text">{{data.extras.created_by}}</span></p>
      <p class="label-text">On: <span class="info-text">{{data.extras.created_on | date:'dd LLLL yyyy HH:mm'}}</span></p>
    </div>
    <div class="col-3">
          <p class="label-text">Manually modified: <span class="fa" [ngClass]="data.extras.modified_on ? 'fa-check-square' : 'fa-times'"></span></p>
      <p class="label-text">Modified by: <span class="info-text" style="overflow-wrap: break-word;">{{data.extras.modified_by ?? '-'}}</span></p>
      <p class="label-text">On: <span class="info-text">{{(data.extras.modified_on | date:'dd LLLL yyyy HH:mm') ?? '-'}}</span></p>
    </div>
    <div class="col-3">
      <p class="label-text">Usage Count: <span class="info-text">{{data.extras.usage_count}}</span></p>
      <p class="label-text">Popularity: <span class="info-text">{{data.extras.popularity | number}}</span></p>
      <p class="label-text">Last used: <span class="info-text">{{(data.extras.last_used | date:'dd LLLL yyyy HH:mm') ?? '-'}}</span></p>
    </div>
  </div>
</div>

  <div *ngIf="loading">
    <ngx-spinner> </ngx-spinner>
  </div>

  <div class="modal-body" *ngIf="!loading">
    <div class="row">
      <div class="col-6">
        <form [formGroup]="editForm" autocomplete="off">
          <div class="row pb-2">
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>Name</mat-label>
              <input matInput formControlName="name" placeholder="Enter name">
            </mat-form-field>
          </div>

          <div class="row row-spacer">
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>Name2</mat-label>
              <input matInput formControlName="name2" placeholder="Enter name2">
            </mat-form-field>
          </div>

          <mat-slide-toggle formControlName="forceName"
            >Force name</mat-slide-toggle
          >

          <mat-error *ngIf="locationWithForcedName"
            >Same Location with forced name already exist.</mat-error
          >

          <div *ngIf="locationWithForcedName">
            <mat-error
              >name: {{ locationWithForcedName.address.name }}</mat-error
            >
            <mat-error
              >name2: {{ locationWithForcedName.address.name2 }}</mat-error
            >
          </div>

          <div class="row row-spacer">
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>Street</mat-label>
              <input [ngClass]="{
                'validator-error':
                  editForm.controls['street'].invalid &&
                  editForm.controls['street'].touched
              }" required matInput formControlName="street" placeholder="Enter street">
            </mat-form-field>
          </div>

          <div class="row row-spacer">
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>Street2</mat-label>
              <input matInput formControlName="street2" placeholder="Enter street2">
            </mat-form-field>
          </div>

          <div class="row row-spacer">
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>House number</mat-label>
              <input matInput formControlName="houseNumber" placeholder="Enter house number">
            </mat-form-field>
          </div>

          <div class="row row-spacer">
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>Zip</mat-label>
              <input required [ngClass]="{
                'validator-error':
                  editForm.controls['zip'].invalid &&
                  editForm.controls['zip'].touched
              }" matInput formControlName="zip" placeholder="Enter zip">
            </mat-form-field>
          </div>

          <div class="row row-spacer">
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>City</mat-label>
              <input required [ngClass]="{
                'validator-error':
                  editForm.controls['city'].invalid &&
                  editForm.controls['city'].touched
              }" matInput formControlName="city" placeholder="Enter city">
            </mat-form-field>
          </div>

          <div class="row row-spacer">
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>Country code</mat-label>
              <input matInput formControlName="countryCode" placeholder="Enter country code">
            </mat-form-field>
          </div>

          <div class="row row-spacer">
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>Country</mat-label>
              <input matInput formControlName="country" placeholder="Enter country">
            </mat-form-field>
          </div>

          <div class="row row-spacer">
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>Latitude</mat-label>
              <input
              [ngClass]="{
                'validator-error':
                  editForm.controls['lat'].invalid &&
                  editForm.controls['lat'].touched
              }"
              matInput required formControlName="lat" placeholder="Enter latitude" (ngModelChange)="latLngChange()">
            </mat-form-field>
          </div>

          <div class="row row-spacer">
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>Longtitude</mat-label>
              <input
              [ngClass]="{
                'validator-error':
                  editForm.controls['lng'].invalid &&
                  editForm.controls['lng'].touched
              }"
              matInput required formControlName="lng" placeholder="Enter longtitude" (ngModelChange)="latLngChange()">
            </mat-form-field>
          </div>

          <div class="row row-spacer">
            <mat-form-field class="custom-mat" appearance="outline">
              <mat-label>Type </mat-label>
              <mat-select formControlName="locationType" required style="height: 30px;">
                <mat-option *ngFor="let locationType of locationTypes" [value]="locationType.type">
                  {{locationType.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </form>
      </div>
      <div class="col-6">
        <div id="mapToggleContainer">
          <button id="mapToggle" (click)="toggleMapType()">{{setMapTypeToggleName}}</button>
        </div>
        <agm-map
          *ngIf="mapPosition"
          [zoom]="mapPosition.zoom"
          [latitude]="mapPosition.lat"
          [longitude]="mapPosition.lng"
          [draggable]="true"
          [mapTypeId]="mapType"
          (mapClick)="mapClickedMarker($event)"
        >
          <agm-marker
            *ngIf="marker"
            [markerDraggable]="true"
            [latitude]="marker.lat"
            [longitude]="marker.lng"
            (dragEnd)="markerDragEnd($event)"
          >
          </agm-marker
          ><!-- [iconUrl]="'assets/image/transparent.png'" -->
        </agm-map>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="row row-spacer">
      <div class="col-12 d-flex">
        <button (click)="bsModalRef.hide()" class="btn btn-danger" style="margin-right:10px !important;">
          Cancel
        </button>
        <button
          class="btn btn-ancotrans"
          [disabled]="editForm.invalid"
          (click)="saveChanges()"
        >
          Save changes
        </button>
      </div>
    </div>
  </div>
