import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { EdifactDocument } from 'src/app/models/edifact-document.interface';

@Component({
  selector: 'app-edi-fact',
  templateUrl: './edi-fact.component.html',
  styleUrls: ['./edi-fact.component.scss']
})
export class EdiFactComponent implements OnInit {
deleteFile(_t69: any) {
throw new Error('Method not implemented.');
}
openDetails(_t69: any) {
throw new Error('Method not implemented.');
}

  ngOnInit(): void {
    this.loading = false
  }
  value = '';
  loading: boolean = true;
  edifactDocuments: EdifactDocument[] = [];
  dataSource: MatTableDataSource<EdifactDocument>;
  displayedColumns: string[] = [ 'customername',
  'customernumber',
  'documentType',
  'documentFormat',
  'active',
  'schedule',
  'communicationType','actions'];

  constructor() {
    this.dataSource = new MatTableDataSource(this.edifactDocuments);
  }

  uploadFile(): void {
    const fakeEdifactContent = `
      UNA:+.? '
      UNB+UNOC:3+1234567890:14+9876543210:14+210701:1400+1'
      UNH+1+ORDERS:D:96A:UN'
      BGM+220+000000001'
      DTM+137:20210701:102'
      UNT+6+1'
      UNZ+1+1'
    `;

    const fakeEdifactDocument: EdifactDocument = {
      customername: 'Customer A',
      customernumber: 12345,
      documentType: 'Order',
      documentFormat: 'EDIFACT',
      active: true,
      schedule: 'Daily',
      communicationType: 'API'
    };

    this.edifactDocuments.push(fakeEdifactDocument);
    this.dataSource.data = this.edifactDocuments;
  }

  applyFilter(filterValue?: string): void {
    this.dataSource.filter = filterValue?.trim().toLowerCase() ?? '';
  }
}
