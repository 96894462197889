<div class="row">
  <div class="col-12">
    <div class="header d-flex align-items-center justify-content-between">
      <h3 class="mb-0">EDIFACT</h3>
      <div class="col-sm-12 col-md-6 col-xl-4 text-end">
        <button class="col-sm-12 col-md-5 col-xl-5 btn btn-ancotrans mb-2" (click)="uploadFile()">Upload document</button>
      </div>
    </div>
  </div>

  <div class="row mb-2 p-3">
    <div class="d-flex">
      <mat-form-field class="col-md-4 col-sm-12 col-xl-2" appearance="outline">
        <mat-label>Search</mat-label>
        <input
          matInput
          type="text"
          [(ngModel)]="value"
          (ngModelChange)="applyFilter()"
        />
        <mat-icon *ngIf="!value" matSuffix>search</mat-icon>
        <button
          *ngIf="value"
          matSuffix
          mat-icon-button
          aria-label="Clear"
          (click)="value = ''; applyFilter()"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </div>
  </div>

  <div class="mat-elevation-z8" style="max-height: 70vh; overflow: auto">
    <div
      style="
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(255, 255, 255, 0.6);
        position: absolute;
        width: 84.9vw;
        height: 70%;
        z-index: 1;
      "
      *ngIf="loading"
    >
      <ngx-spinner [fullScreen]="false"> </ngx-spinner>
    </div>

    <table
      class="table table-striped"
      mat-table
      [dataSource]="dataSource"
      matSort
      style="min-width: 1000px"
    >
      <ng-container matColumnDef="customername">
        <th class="th__header" mat-header-cell *matHeaderCellDef>Customer name</th>
        <td mat-cell *matCellDef="let element">{{ element.customername }}</td>
      </ng-container>

      <ng-container matColumnDef="customernumber">
        <th class="th__header" mat-header-cell *matHeaderCellDef>
          Customer number
        </th>
        <td mat-cell *matCellDef="let element">{{ element.customernumber }}</td>
      </ng-container>
      <ng-container matColumnDef="documentType">
        <th class="th__header" mat-header-cell *matHeaderCellDef>
          Doc. type
        </th>
        <td mat-cell *matCellDef="let element">{{ element.documentType }}</td>
      </ng-container>
      <ng-container matColumnDef="documentFormat">
        <th class="th__header" mat-header-cell *matHeaderCellDef>
          Doc. format
        </th>
        <td mat-cell *matCellDef="let element">{{ element.documentFormat }}</td>
      </ng-container>
      <ng-container matColumnDef="active">
        <th class="th__header" mat-header-cell *matHeaderCellDef>
          Active
        </th>
        <td mat-cell *matCellDef="let element">{{ element.active }}</td>
      </ng-container>
      <ng-container matColumnDef="schedule">
        <th class="th__header" mat-header-cell *matHeaderCellDef>
          Schedule
        </th>
        <td mat-cell *matCellDef="let element">{{ element.schedule }}</td>
      </ng-container>
      <ng-container matColumnDef="communicationType">
        <th class="th__header" mat-header-cell *matHeaderCellDef>
          Communication type
        </th>
        <td mat-cell *matCellDef="let element">{{ element.communicationType }}</td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th
          class="th__header"
          mat-header-cell
          *matHeaderCellDef
          style="text-align: right; padding-right: 120px !important"
        >
          Actions
        </th>
        <td
          mat-cell
          *matCellDef="let element"
          style="text-align: right"
          (click)="$event.stopPropagation()"
        >
          <button
            matTooltip="Groups manage"
            [matTooltipPosition]="'above'"
            class="btn btn-ancotrans"
            (click)="openDetails(element)"
          >
            <i class="fa fa-low-vision"></i>
          </button>
          <button
            style="margin-left: 5px"
            matTooltip="Delete"
            [matTooltipPosition]="'above'"
            class="btn btn-danger"
            (click)="deleteFile(element)"
          >
            <i class="fa fa-trash"></i>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>

  <mat-paginator
    [pageSizeOptions]="[10, 25, 100]"
    [pageSize]="25"
  ></mat-paginator>
</div>
