<!-- https://getbootstrap.com/docs/4.0/components/pagination/ -->

<div *ngIf="totalCountShow" class="total-search-results">Total search results {{itemsLengthValue}}</div>
<nav aria-label="Page navigation example" *ngIf="numberOfPages > 1 && itemsLengthValue > 0">
  <ul class="pagination">
    <li class="page-item " (click)="previousPage()" [ngClass]="{'disabled': offset == 0, 'pointer': currentPage > 1}">
      <div class="page-link">Previous</div>
    </li>
    <li class="page-item" *ngIf="currentPage > 3" (click)="changePage(1)">
      <div class="page-link pointer">1</div>
    </li>

    <li class="page-item disabled" *ngIf="currentPage > 4">
      <div class="page-link">...</div>
    </li>

    <li class="page-item" *ngFor="let page of pages" [ngClass]="{'active': page == currentPage}" (click)="changePage(page)">
      <div class="page-link pointer">{{page}}</div>
    </li>
    
    <li class="page-item disabled" *ngIf="currentPage < numberOfPages - 3">
      <div class="page-link">...</div>
    </li>

    <li class="page-item" *ngIf="currentPage < numberOfPages - 2" (click)="changePage(numberOfPages)">
      <div class="page-link pointer">{{numberOfPages}}</div>
    </li>

    <li class="page-item" (click)="nextPage()" [ngClass]="{'disabled': itemsLengthValue <= offset+limit, 'pointer': currentPage < numberOfPages}">
      <div class="page-link">Next</div>
    </li>
  </ul>
</nav>
