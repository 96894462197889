import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from "@angular/router";

@Injectable()
export class AuthManager implements CanActivate {

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return true;
  }


}
