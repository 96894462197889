import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import {
  UserList,
  UserModelLegacy,
} from 'src/app/models/system-user.interface';
import { UserService } from 'src/app/services/user.service';
import { ModalWindowComponent } from 'src/app/shared/modal-window/modal-window.component';
import { AddUserComponent } from './add-user/add-user.component';
import { UserDetailsComponent } from './user-details/user-details.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { of } from 'rxjs';

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss'],
})
export class UserManagementComponent implements OnInit, AfterViewInit {
  value = '';
  dataSource = new MatTableDataSource<UserList>([]);
  loading: boolean = true;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  ngOnInit(): void {
    this.toggleSpinner(true);
    this.getUserList();
  }
  constructor(
    private userService: UserService,
    public modal: BsModalService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private bsModalRef: BsModalRef
  ) {
    this.dataSource = new MatTableDataSource<UserList>([]);
  }

  displayedColumns: string[] = [
    'username',
    'userCreateDate',
    'userLastModifiedDate',
    'userStatus',
    'actions',
  ];

  createNewUser(): void {
    const addUser = this.modal.show(AddUserComponent, {
      initialState: {
        title: 'Create User',
      },
      class: 'modal-sm',
      backdrop: 'static',
    });

    addUser?.onHide?.subscribe(() => {
      this.getUserList();
    });
  }

  resendTemporarypassword(element: UserList): void {
    this.bsModalRef = this.modal.show(ModalWindowComponent, {
        class: 'modal-lg',
        initialState: {
          showContinueButton: true,
          title: 'Send temporary password',
          data: `In a moment for the user with email: ${this.getEmailAttribute(element.Attributes)} a temporary password will be sent to log in to the service, do you want to continue?`,
        },

        backdrop: 'static',
      });

      this.bsModalRef.content.onContinueAction.pipe(
        switchMap((result: boolean) => {
          if (result) {
            return this.userService.resendTemporarypasswordPassword(element.Username);
          } else {
            return of(null);
          }
        }),
        switchMap(async () => this.getUserList())
      ).subscribe(() => {
      }, (error:any) => {
      });
  }

  changeEmail(userId: string): void {
    const changeEmailModal = this.modal.show(AddUserComponent, {
      initialState: {
        title: 'User email change',
        userId: userId
      },
      class: 'modal-sm',
      backdrop: 'static',
    });

    changeEmailModal?.onHide?.subscribe(() => {
      this.getUserList();
    });
  }

  updateUserAccess(element: UserList, action: string): void {
    this.userService.updateUserAccess(element.Username, action).subscribe(
      (response) => {
        this.getUserList();
        if (action == 'disable') {
          this.userService
            .disableActiveCampaignUser(element.Username)
            .subscribe();
        }
        this.toastr.success('Access updated', 'Success');
      },
      () => {
        this.toastr.error('Something went wrong', 'Failure');
      }
    );
  }

  deleteUser(element: UserList): void {
    this.userService.deleteUser(element.Username).subscribe(
      (response: any) => {
        this.getUserList();
        this.toastr.success('User deleted', 'Success');
      },
      (err) => {
        console.log(err);
        if (err.error.message) {
          this.toastr.error(err.error.message, 'Failure');
        } else {
          this.toastr.error('Something went wrong', 'Failure');
        }
      }
    );
  }

  getEmailAttribute(attributes: any[]): string | undefined {
    const emailAttribute = attributes.find(attr => attr.Name === 'email');
    return emailAttribute ? emailAttribute.Value : undefined;
  }

  getUserList(): void {
    if (this.value.length > 2 || this.value == '') this.toggleSpinner(true);
    this.userService
      .getUsersList(this.value)
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((x) => {
        this.toggleSpinner(false);
        (this.dataSource = new MatTableDataSource<UserList>(x.Users)),
          (this.dataSource.paginator = this.paginator);
        this.dataSource.sort = this.sort;
      });
  }

  openModal(permissionsLegacy: UserModelLegacy): void {
    this.modal.show(ModalWindowComponent, {
      class: 'modal-lg',
      initialState: {
        title: 'Legacy permissions',
        data: JSON.stringify(permissionsLegacy?.rights),
      },

      backdrop: 'static',
    });
  }

  openUserDetails(element: UserList): void {
    this.modal.show(UserDetailsComponent, {
      class: 'modal-lg',
      initialState: {
        title: 'User groups manager',
        data: element,
      },

      backdrop: 'static',
    });
  }

  getUserLegacyPermissions(element: any): void {
   let emailAttribute = element.Attributes.find((attr: any) => attr.Name === 'email')?.Value!;

    this.userService
      .getUserLegacyPermissions(emailAttribute)
      .subscribe((x) => this.openModal(x));
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  toggleSpinner(show: boolean) {
    this.loading = show;
    if (show) {
      this.spinner.show();
    } else {
      this.spinner.hide();
    }
  }
}
