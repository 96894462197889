import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class UtilsService {

  checkContainerNumber(container: string): boolean {
    return this.isISO6346Number(container, this.getContainerCodeCharValueByDictionary);
  }

  constructor(private toastr: ToastrService) {}

  private getContainerCodeCharValueByDictionary(shippingContainerChar: string): number {
    if (!shippingContainerChar || shippingContainerChar.length != 1) return -1;
    const alphaReductionDictionary: { [character: string]: number } = {
      A: 10,
      B: 12,
      C: 13,
      D: 14,
      E: 15,
      F: 16,
      G: 17,
      H: 18,
      I: 19,
      J: 20,
      K: 21,
      L: 23,
      M: 24,
      N: 25,
      O: 26,
      P: 27,
      Q: 28,
      R: 29,
      S: 30,
      T: 31,
      U: 32,
      V: 34,
      W: 35,
      X: 36,
      Y: 37,
      Z: 38,
    };
    return alphaReductionDictionary[shippingContainerChar] || parseInt(shippingContainerChar, 10);
  }

  private isISO6346Number(containerNumber: string, getCharValue: (c: string) => number): boolean {
    if (!containerNumber || /^\s+$/.test(containerNumber) || !getCharValue) return false;

    const isoPattern: RegExp = /^[A-Z]{4}\d{6}\d$/;

    if (!isoPattern.test(containerNumber)) return false;

    const checkDigit: number = Number(containerNumber[containerNumber.length - 1]);

    let calcSum: number = 0;
    for (let i = 0; i < containerNumber.length - 1; i++) {
      let extractedValue: number = 0;
      const contNumChar: string = containerNumber[i];
      extractedValue = i < 4 ? getCharValue(contNumChar) : Number(contNumChar);
      calcSum += extractedValue * Math.pow(2, i);
    }

    const checkSum: number = (calcSum % 11) % 10;

    return checkDigit === checkSum;
  }


  clone<T>(object: T): T {
    return JSON.parse(JSON.stringify(object));
  }
}
