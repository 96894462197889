import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Auth } from 'aws-amplify';
import { ToastrService } from 'ngx-toastr';
import { from, Observable } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private toastr: ToastrService) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return from(Auth.currentSession()).pipe(
      switchMap((auth: any) => {
        let binaryToken = auth.accessToken.jwtToken;
        let with_auth_request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${binaryToken}`,
          },
        });
        return next.handle(with_auth_request);
      }),
      catchError((err) => {
        if (err.status === 400) {
          if (err.error[Object.keys(err.error)[0]]?.length > 0) {
            err.error[Object.keys(err.error)[0]].forEach((element: any) => {
              this.toastr.error(element, 'Failure');
            });
          } else {
            err.error.forEach((element: any) => {
              this.toastr.error(element.error, 'Failure');
            });
          }
        }
        if (err.status === 401) {
          this.toastr.error('Not authorized', 'Failure');
        }
        if (err.status === 403) {
          this.toastr.error('Access denied', 'Failure');
        }
        if (err.status === 500) {
          this.toastr.error('Server error', 'Failure');
        }
        if (err.status === 404) {
          this.toastr.error('Not found', 'Failure');
        }
        console.log('Error log: ', err);
        return next.handle(request);
      })
    );
  }
}
