import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ContainerService } from 'src/app/services/container.service';
import { runtimeEnvironment } from 'src/environments/environment.docker';
import { ContainerInterface } from '../../models/container.interface';
import { CountryInterface } from '../../models/country.interface';
import { PaginationInterface } from '../../models/pagination.interface';
import { ContainerDeleteModalComponent } from './container-delete-modal/container-delete-modal.component';
import { ContainerModalComponent } from './container-modal/container-modal.component';

declare var $: any;

@Component({
  templateUrl: './container.component.html',
  styleUrls: ['./container.component.scss'],
})
export class ContainerComponent {
  loading: boolean = true;
  selectedContainer: ContainerInterface | undefined;
  containerContent: PaginationInterface<ContainerInterface[]> | undefined;
  countries: CountryInterface[] = [
    {
      name: 'All',
      code: '',
      official_name: 'All countries',
    },
  ];

  limit: number = 10;
  offset: number = 0;
  totalCount: number = 0;

  sortValues: any = {
    containerNumber: undefined,
    type: undefined,
    ownerCode: undefined,
    ownerCompany: undefined,
    countryCode: undefined,
  };

  searchForm: FormGroup = new FormGroup({
    containerNumber: new FormControl(''),
    type: new FormControl(''),
    ownerCode: new FormControl(''),
    ownerCompany: new FormControl(''),
    countryCode: new FormControl(''),
  });

  constructor(
    private http: HttpClient,
    private spinner: NgxSpinnerService,
    private modal: BsModalService,
    private containerService: ContainerService
  ) {
    this.getContainers();
    this.getCountries().then(
      () => {
        this.searchForm.controls['countryCode'].patchValue(
          this.countries[0].code
        );
      },
      () => {}
    );
  }

  resetOffset() {
    if (this.containerContent) {
      this.offset = 0;
    }
  }

  async getCountries(): Promise<boolean> {
    this.toggleSpinner(true);
    return new Promise((resolve, reject) => {
      let url = runtimeEnvironment.geodataUrl + '/v1/admin/countries';

      this.http.get(url).subscribe(
        (data) => {
          let countries = data as CountryInterface[];
          this.countries = this.countries.concat(countries);
          resolve(true);
          this.toggleSpinner(false);
        },
        () => {
          reject();
          this.toggleSpinner(false);
        }
      );
    });
  }

  getContainers() {
    this.toggleSpinner(true);
    this.containerService.getContainers(this.createQueryString()).subscribe(
      (data) => {
        this.containerContent = data as PaginationInterface<
          ContainerInterface[]
        >;
        this.totalCount = this.containerContent.total_count;

        this.toggleSpinner(false);
      },
      () => {
        this.toggleSpinner(false);
      }
    );
  }

  clearForm() {
    this.searchForm.controls['containerNumber'].patchValue('');
    this.searchForm.controls['type'].patchValue('');
    this.searchForm.controls['ownerCode'].patchValue('');
    this.searchForm.controls['ownerCompany'].patchValue('');
    this.searchForm.controls['countryCode'].patchValue('');
  }

  private createQueryString(): string {
    let qs = '?';
    qs += 'limit=' + this.limit;
    qs += '&offset=' + this.offset;

    if (this.searchForm.get('containerNumber')?.value) {
      qs += '&containerNumber=' + this.searchForm.get('containerNumber')?.value;
    }

    if (this.searchForm.get('type')?.value) {
      qs += '&type=' + this.searchForm.get('type')?.value;
    }

    if (this.searchForm.get('ownerCode')?.value) {
      qs += '&ownerCode=' + this.searchForm.get('ownerCode')?.value;
    }

    if (this.searchForm.get('ownerCompany')?.value) {
      qs += '&ownerCompany=' + this.searchForm.get('ownerCompany')?.value;
    }

    if (this.searchForm.get('countryCode')?.value) {
      qs += '&countryCode=' + this.searchForm.get('countryCode')?.value;
    }

    qs += this.getSort();
    return qs;
  }

  private getSort() {
    if (this.sortValues.containerNumber != undefined) {
      return (
        '&sortName=containerNumber&sortDirection=' +
        this.sortValues.containerNumber
      );
    } else if (this.sortValues.type != undefined) {
      return '&sortName=type&sortDirection=' + this.sortValues.type;
    } else if (this.sortValues.ownerCode != undefined) {
      return '&sortName=ownerCode&sortDirection=' + this.sortValues.ownerCode;
    } else if (this.sortValues.ownerCompany != undefined) {
      return (
        '&sortName=ownerCompany&sortDirection=' + this.sortValues.ownerCompany
      );
    } else if (this.sortValues.countryCode != undefined) {
      return (
        '&sortName=countryCode&sortDirection=' + this.sortValues.countryCode
      );
    }
    return '';
  }

  toggleSpinner(show: boolean) {
    this.loading = show;
    if (show) {
      this.spinner.show();
    } else {
      this.spinner.hide();
    }
  }

  sortContainers(name: string) {
    let direction = this.sortValues[name];
    direction = direction == 'asc' ? 'desc' : 'asc';
    this.sortValues = {
      type: undefined,
      address: undefined,
      name: undefined,
      zip: undefined,
      city: undefined,
      country: undefined,
      usageCount: undefined,
      score: undefined,
      createdAt: undefined,
      lastUsedAt: undefined,
      lastModifiedAt: undefined,
    };
    this.sortValues[name] = direction;
    this.getContainers();
  }

  onDelete(containerNo: string) {
    const modalRef = this.modal.show(ContainerDeleteModalComponent, {
      class: 'modal-md',
      initialState: {
        containerNo: containerNo,
      },
      backdrop: 'static',
    });

    modalRef.content?.closed.subscribe(() => {
      modalRef.hide();
      this.getContainers();
    });
  }

  onEdit(containerNo?: string): void {
    const modalRef = this.modal.show(ContainerModalComponent, {
      class: 'modal-md',
      initialState: {
        containerContent: this.containerContent?.content,
        containerNumber: containerNo,
      },
      backdrop: 'static',
    });

    modalRef.content?.closed.subscribe(() => {
      modalRef.hide();
      this.getContainers();
    });
  }
}
