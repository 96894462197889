import {
  HttpBackend,
  HttpClient,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  CustomerInterfaceGeneric,
  CustomerResponseDrayos,
  CustomerResponseNav,
  CustomerResponsGenerice,
  CustomersLinkInterface,
} from '../models/customer.interface';
import { runtimeEnvironment } from 'src/environments/environment.docker';
import { Auth } from 'aws-amplify';

@Injectable({
  providedIn: 'root',
})
export class IdentityService {
  endpoint = `${runtimeEnvironment.cognitoApiUrl}/identityServiceAncoPanel`;
  private urlAncoPanel = '';

  private apiUrlDr = 'https://test-identity.ancotrans.com/api/DrayosCustomers';

  private linkUrl = 'https://test-identity.ancotrans.com/api/Customers';
  private httpClient: HttpClient;
  token: string;

  headers = new HttpHeaders();

  constructor(
    private http: HttpClient,
    handler: HttpBackend,
    private httpBackend: HttpClient
  ) {
    this.httpBackend = new HttpClient(handler);

    Auth.currentAuthenticatedUser().then((user) => {
      this.token = user.signInUserSession.idToken.jwtToken;
      this.headers = new HttpHeaders({
        Authorization: `Bearer ${this.token}`,
      });
    });
  }

  getCustomersDrayos(
    searchString: string,
    sortBy: string,
    sortOrder: number,
    page: number,
    size: number,
    isLinked: boolean
  ): Observable<CustomerResponseDrayos> {
    const params = new HttpParams()
      .set('searchString', searchString)
      .set('sortBy', sortBy)
      .set('sortOrder', sortOrder.toString())
      .set('page', page.toString())
      .set('size', size.toString())
      .set('isLinked', isLinked);

    return this.httpBackend.get<CustomerResponseDrayos>(
      `${this.endpoint}/DrayosCustomers`,
      { headers: this.headers, params }
    );
  }

  private apiUrlNav =
    'https://test-identity.ancotrans.com/api/identityServiceAncoPanel';

  getCustomersNav(
    searchString: string,
    sortBy: string,
    sortOrder: number,
    page: number,
    size: number,
    isLinked: boolean
  ): Observable<CustomerResponseNav> {
    const params = new HttpParams()
      .set('searchString', searchString)
      .set('sortBy', sortBy)
      .set('sortOrder', sortOrder.toString())
      .set('page', page.toString())
      .set('size', size.toString())
      .set('isLinked', isLinked);

    return this.httpBackend.get<CustomerResponseNav>(
      `${this.endpoint}/NavCustomers`,
      { headers: this.headers, params }
    );
  }

  getCustomers(
    searchString: string,
    sortBy: string,
    sortOrder: number,
    page: number,
    size: number
  ): Observable<any> {
    const params = new HttpParams()
      .set('searchString', searchString)
      .set('sortBy', sortBy)
      .set('sortOrder', sortOrder.toString())
      .set('page', page.toString())
      .set('size', size.toString());

    return this.httpBackend.get<any>(`${this.endpoint}/Customers`, {
      headers: this.headers,
      params: params,
    });
  }

  linkCustomersNav(customerId: string, navNo: string): Observable<any> {
    const body = {
      customerId: customerId,
      navNo: navNo
    };
    //const url = `${this.endpoint}/${customerId}/navcustomer/${navNo}`;
    return this.httpBackend.patch(
      `${this.endpoint}/navcustomer`,
      body,
      { headers: this.headers }
    );
  }

  linkCustomersDrayos(customerId: string, drayosId?: string): Observable<any> {
    const body = {
      customerId: customerId,
      drayosId: drayosId
    };
    //const url = `${this.endpoint}/${customerId}/drayoscustomer/${drayosId}`;
    return this.httpBackend.patch(
      `${this.endpoint}/drayoscustomer`,
      body,
      { headers: this.headers }
    );
  }
}
