

<div class="container d-flex justify-content-center">
    <amplify-authenticator [loginMechanisms]="['email']">
      <ng-template amplifySlot="header">
          <div style="padding: var(--amplify-space-large); text-align: center">
            <img
              src="assets/image/logo.svg"
            />
          </div>
        </ng-template>
      <ng-template
        amplifySlot="authenticated"
        let-user="user"
        let-signOut="signOut"
      >
        <h2>Welcome, {{ user.username }}!</h2>
        <button (click)="signOut()">Sign Out</button>
      </ng-template>
    </amplify-authenticator>
  </div>