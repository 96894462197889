import { Injectable } from "@angular/core";
import { CountryInterface } from "../models/country.interface";

@Injectable()
export class CountryService {
  countries: CountryInterface[] = [];
  constructor() {
    this.countries.push({ locale: 'DK', name: 'Denmark' });
    this.countries.push({ locale: 'SE', name: 'Sweden' });
    this.countries.push({ locale: 'DE', name: 'Germany' });
    this.countries.push({ locale: 'NL', name: 'Netherlands' });
    this.countries.push({ locale: 'GB', name: 'Undefined' });

  }

  getCountries(): CountryInterface[] {
    return this.countries;
  }

  getCountryByLocale(locale: string) {
    let localSplit = locale.split('_');
    if (localSplit.length != 2) {
      return null;
    }

    let response = this.countries.filter(x => x.locale == localSplit[1]);
    return response && response.length > 0 ? response[0] : null;
  }

  getCountryByShortCode(shortCode: string) {
    let response = this.countries.filter(x => x.locale == shortCode);
    return response && response.length > 0 ? response[0] : null;
  }

  getCountriesWithoutUndefined(): CountryInterface[] {
    return this.countries.filter(x => x.name != 'Undefined');
  }

}
