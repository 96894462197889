import { HttpClient } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { PaginationInterface } from 'src/app/models/pagination.interface';
import { LocationService } from 'src/app/services/location.service';
import { LocationTypeInterface } from '../../../models/location-type.interface';
import { LocationInterface } from '../../../models/location.interface';
import { MapMarker } from '../../../models/map-marker.interface';
import { MapPosition } from '../../../models/map-position.interface';
import { MapTypeId } from '@agm/core';

declare const google: any;

export enum LocationFormFields {
  name = 'name',
  name2 = 'name2',
  street = 'street',
  street2 = 'street2',
  houseNumber = 'houseNumber',
  zip = 'zip',
  city = 'city',
  countryCode = 'countryCode',
  country = 'country',
  lng = 'lng',
  lat = 'lat',
  locationType = 'locationType',
  forceName = 'forceName',
}

@Component({
  templateUrl: './edit-location.component.html',
  styleUrls: ['./edit-location.component.scss'],
})
export class EditLocationComponent implements OnInit, OnDestroy {
  @Input() title = '';
  @Input() data: LocationInterface;
  @Output() closed: EventEmitter<never> = new EventEmitter<never>();

  private _locationTypes: LocationTypeInterface[] = [];

  @Input() get locationTypes(): LocationTypeInterface[] {
    // Filter out items where type is not 'all'
    return this._locationTypes.filter(item => item.type !== 'ALL');
  }

  set locationTypes(value: LocationTypeInterface[]) {
    this._locationTypes = value;
  }

  locationContent: PaginationInterface<LocationInterface[]> | undefined;
  mapPosition: MapPosition | undefined;
  marker: MapMarker | undefined;

  editForm: FormGroup;
  formFields = LocationFormFields;
  loading = false;
  isChecked = false;
  mapType = 'roadmap';
  destroy$ = new Subject();

  locationWithForcedName: LocationInterface | undefined;

  get setMapTypeToggleName(): string {
    return this.mapType === 'roadmap' ? 'Satellite' : 'Roadmap';
  }

  constructor(
    http: HttpClient,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    public route: ActivatedRoute,
    private fb: FormBuilder,
    public bsModalRef: BsModalRef,
    private locationService: LocationService
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.setMap();
    this.listenOnForceNameChange();
  }

  ngOnDestroy(): void {
    this.destroy$.complete();
  }

  private getQueryString(): string {
    let qs = '?';
    let searchType = this.editForm.get('locationType')?.value;
    qs += 'type=' + searchType;

    let name = this.editForm.get('name')?.value;
    if (name) {
      qs += '&name=' + name;
    }

    let street = this.editForm.get('street')?.value;
    if (street) {
      qs += '&streetName=' + street;
    }

    let houseNumber = this.editForm.get('houseNumber')?.value;
    if (houseNumber) {
      qs += '&HouseNumber=' + houseNumber;
    }

    let zip = this.editForm.get('zip')?.value;
    if (zip) {
      qs += '&zip=' + zip;
    }

    let city = this.editForm.get('city')?.value;
    if (city) {
      qs += '&city=' + city;
    }

    return qs;
  }

  getLocations() {
    this.locationService
      .getLocations(this.getQueryString())
      .subscribe((data) => {
        this.locationContent = data as PaginationInterface<LocationInterface[]>;
        this.locationWithForcedName = this.locationContent?.content.find(
          (x) => x.extras.force_name === true
        );
      });
  }

  private listenOnForceNameChange(): void {
    this.editForm
      .get(this.formFields.forceName)
      ?.valueChanges.pipe(
        takeUntil(this.destroy$),
        tap((value) => {
          if (value) {
            this.getLocations();
          } else {
            this.locationWithForcedName = undefined;
          }
        })
      )
      .subscribe();
  }

  initForm(): void {
    this.editForm = this.fb.group({
      [this.formFields.name]: [this.data?.address.name],
      [this.formFields.name2]: [this.data?.address.name2],
      [this.formFields.street]: [
        this.data?.address.street,
        [Validators.required],
      ],
      [this.formFields.street2]: [this.data?.address.street2],
      [this.formFields.houseNumber]: [this.data?.address.house_no],
      [this.formFields.zip]: [this.data?.address.zip, [Validators.required]],
      [this.formFields.city]: [this.data?.address.city, [Validators.required]],
      [this.formFields.countryCode]: [
        {
          value: this.data?.address.country.code,
          disabled: true,
        },
        [Validators.required],
      ],
      [this.formFields.country]: [
        {
          value: this.data?.address.country.name,
          disabled: true,
        },
        [Validators.required],
      ],
      [this.formFields.lng]: [
        this.data?.address.geo_decimal.lng,
        [Validators.required],
      ],
      [this.formFields.lat]: [
        this.data?.address.geo_decimal.lat,
        [Validators.required],
      ],
      [this.formFields.locationType]: [
        this.data?.location.type,
        [Validators.required],
      ],
      [this.formFields.forceName]: [this.data?.extras.force_name],
    });
  }

  saveChanges() {
    this.toggleSpinner(true);

    const payload = {
      address: {
        name: this.editForm.controls[this.formFields.name].value,
        name2: this.editForm.controls[this.formFields.name2].value,
        street: this.editForm.controls[this.formFields.street].value,
        street2: this.editForm.controls[this.formFields.street2].value,
        house_no: this.editForm.controls[this.formFields.houseNumber].value,
        zip: this.editForm.controls[this.formFields.zip].value,
        city: this.editForm.controls[this.formFields.city].value,
        country: {
          code: this.editForm.controls[this.formFields.countryCode].value,
          country: this.editForm.controls[this.formFields.country].value,
        },
        geo_decimal: {
          lat: this.editForm.controls[this.formFields.lat].value,
          lng: this.editForm.controls[this.formFields.lng].value,
        },
      },
      location: {
        type: this.editForm.controls[this.formFields.locationType].value,
      },
      extras: {
        force_name: this.editForm.controls[this.formFields.forceName].value,
        validation_state: 'OK',
      },
    };

    this.locationService
      .updateLocation(payload, this.data.extras.geodata_id)
      .subscribe(
        (data) => {
          this.toggleSpinner(false);
          this.toastr.success('Location has been updated', 'Success');
          this.closed.emit();
        },
        (err) => {
          this.toggleSpinner(false);
          this.toastr.error('Location has not been updated', 'Failure');
        }
      );
  }

  hide(): void {
    this.bsModalRef.hide();
  }

  toggleMapType() {
    this.mapType = this.mapType === 'roadmap' ? 'hybrid' : 'roadmap';
  }

  private setMap() {
    if (!this.data?.address.geo_decimal) {
      return;
    }

    this.mapPosition = {
      lat: this.data.address.geo_decimal.lat,
      lng: this.data.address.geo_decimal.lng,
      zoom: 16,
    } as MapPosition;

    this.marker = {
      lat: this.data.address.geo_decimal.lat,
      lng: this.data.address.geo_decimal.lng,
    };
  }

  markerDragEnd(event: any) {
    if (!this.marker) {
      return;
    }
    let lat = event['coords'].lat;
    let lng = event['coords'].lng;
    this.editForm.controls['lat'].patchValue(lat);
    this.editForm.controls['lng'].patchValue(lng);
    this.setAddress(lat, lng);
  }

  mapClickedMarker(event: any) {
    if (this.marker) {
      return;
    }
  }

  latLngChange() {
    this.marker = {
      lat: this.editForm.get('lat')?.value,
      lng: this.editForm.get('lng')?.value,
    };
  }

  private setAddress(lat: any, lng: any) {
    let geocoder = new google.maps.Geocoder();
    let latlng = new google.maps.LatLng(lat, lng);

    let self = this;
    geocoder.geocode({ latLng: latlng }, (results: any, status: any) => {
      if (results.length == 0) {
        return;
      }
      // only take first result
      for (let component of results[0].address_components) {
        for (let componentType of component.types) {
          if (componentType == 'country') {
            self.editForm.controls['countryCode'].patchValue(
              component.short_name
            );
            self.editForm.controls['country'].patchValue(component.long_name);
          }
        }
      }
    });
  }

  toggleSpinner(show: boolean) {
    this.loading = show;
    if (show) {
      this.spinner.show();
    } else {
      this.spinner.hide();
    }
  }
}
