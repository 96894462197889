import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Auth } from 'aws-amplify';
import { Observable } from 'rxjs/internal/Observable';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private auth: AuthService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return Auth.currentAuthenticatedUser({ bypassCache: true })
    .then((user) => {
      this.auth.groups = user.signInUserSession.accessToken.payload['cognito:groups'];
      return true;
    })
      .catch(() => {
      //  this.auth.hideMenus = true;
        this.router.navigate(['/auth']);
        return false;
      });
  }
}
