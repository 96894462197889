<div class="row">
  <div class="col-12">
    <div class="header d-flex align-items-center justify-content-between">
      <h3 class="mb-0">User management</h3>
      <div class="col-sm-12 col-md-6 col-xl-4 text-end">
        <button class="col-sm-12 col-md-5 col-xl-5 btn btn-ancotrans mb-2" (click)="createNewUser()">Create user</button>
      </div>
    </div>
  </div>

  <div class="row mb-2 p-3">
    <div class="d-flex">
      <mat-form-field class="col-md-4 col-sm-12 col-xl-2" appearance="outline">
        <mat-label>Search</mat-label>
        <input
          matInput
          type="text"
          [(ngModel)]="value"
          (ngModelChange)="getUserList()"
        />
        <mat-icon *ngIf="!value" matSuffix>search</mat-icon>
        <button
          *ngIf="value"
          matSuffix
          mat-icon-button
          aria-label="Clear"
          (click)="value = ''; getUserList()"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </div>
  </div>

  <div class="mat-elevation-z8" style="max-height: 70vh; overflow: auto">
    <div
      style="
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(255, 255, 255, 0.6);
        position: absolute;
        width: 84.9vw;
        height: 70%;
        z-index: 1;
      "
      *ngIf="loading"
    >
      <ngx-spinner [fullScreen]="false"> </ngx-spinner>
    </div>

    <table
      class="table table-striped"
      mat-table
      [dataSource]="dataSource"
      matSort
      style="min-width: 1000px"
    >
      <ng-container matColumnDef="username">
        <th class="th__header" mat-header-cell *matHeaderCellDef>Username</th>
        <td mat-cell *matCellDef="let element">
          <div style="display: flex; align-items: center;">
            <button
            style="margin-left: 5px"
            matTooltip="Change email"
            [matTooltipPosition]="'above'"
            class="btn"
            (click)="changeEmail(element.Username); $event.stopPropagation()"
          >
            <i class="fa fa-edit" style="color: black;"></i>
          </button>
            <span *ngIf="getEmailAttribute(element.Attributes)">
              {{ getEmailAttribute(element.Attributes) }}
            </span>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="userCreateDate">
        <th class="th__header" mat-header-cell *matHeaderCellDef>
          Created date
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.UserCreateDate | date : "medium" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="userLastModifiedDate">
        <th class="th__header" mat-header-cell *matHeaderCellDef>
          Last modified date
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.UserLastModifiedDate | date : "medium" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="userStatus">
        <th class="th__header" mat-header-cell *matHeaderCellDef>
          User status
        </th>
        <td mat-cell *matCellDef="let element">
          <div style="display: flex; align-items: center;">
            <button *ngIf="element.UserStatus === 'FORCE_CHANGE_PASSWORD'"
            style="margin-left: 5px"
            matTooltip="Resend temporary password"
            [matTooltipPosition]="'above'"
            class="btn"
            (click)="resendTemporarypassword(element); $event.stopPropagation()"
          >
            <i class="fa fa-share-square-o" style="color: black;"></i>
          </button>
            <span>
              {{ element.UserStatus }}
            </span>
          </div>
</td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th
          class="th__header"
          mat-header-cell
          *matHeaderCellDef
          style="text-align: right; padding-right: 120px !important"
        >
          Actions
        </th>
        <td
          mat-cell
          *matCellDef="let element"
          style="text-align: right"
          (click)="$event.stopPropagation()"
        >
          <button
            matTooltip="Groups manage"
            [matTooltipPosition]="'above'"
            class="btn btn-ancotrans"
            (click)="openUserDetails(element)"
          >
            <i class="fa fa-low-vision"></i>
          </button>
          <button
            style="margin-left: 5px"
            matTooltip="Legacy permissions"
            [matTooltipPosition]="'above'"
            class="btn btn-ancotrans"
            (click)="getUserLegacyPermissions(element)"
          >
            <i class="fa fa-quote-right"></i>
          </button>
          <button
            *ngIf="!element.Enabled"
            style="margin-left: 5px"
            matTooltip="Enable"
            [matTooltipPosition]="'above'"
            class="btn btn-ancotrans"
            (click)="updateUserAccess(element, 'enable')"
          >
            <i class="fa fa-check"></i>
          </button>
          <button
            *ngIf="element.Enabled"
            style="margin-left: 5px"
            matTooltip="Disable"
            [matTooltipPosition]="'above'"
            class="btn btn-warning"
            (click)="updateUserAccess(element, 'disable')"
          >
            <i class="fa fa-remove"></i>
          </button>
          <button
            style="margin-left: 5px"
            matTooltip="Delete"
            [matTooltipPosition]="'above'"
            class="btn btn-danger"
            (click)="deleteUser(element)"
          >
            <i class="fa fa-trash"></i>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns" style="vertical-align: middle;"></tr>
    </table>
  </div>

  <mat-paginator
    [pageSizeOptions]="[10, 25, 100]"
    [pageSize]="25"
  ></mat-paginator>
</div>
