import { Injectable } from "@angular/core";
import { LanguageInterface } from "../models/language.interface";

@Injectable()
export class LanguageService {
  languages: LanguageInterface[] = [];
  constructor() {
    this.languages.push({ locale: 'da', name: 'Danish' });
    this.languages.push({ locale: 'sv', name: 'Swedish' });
    this.languages.push({ locale: 'de', name: 'Deutch' });
    this.languages.push({ locale: 'nl', name: 'Dutch' });
    this.languages.push({ locale: 'en', name: 'English' });
  }

  getLanguages(): LanguageInterface[] {
    return this.languages;
  }

  getLanguageByLocale(locale: string) {
    let localSplit = locale.split('_');
    if (localSplit.length != 2) {
      return null;
    }

    let response = this.languages.filter(x => x.locale == localSplit[0]);
    return response && response.length > 0 ? response[0] : null;
  }

  getLanguageByShortCode(shortCode: string) {
    let response = this.languages.filter(x => x.locale == shortCode);
    return response && response.length > 0 ? response[0] : null;
  }

}
