<div class="row">
  <div class="col-12">
    <div class="row">
      <div class="col-12">
        <div class="header d-flex align-items-center justify-content-between">
          <h3 class="mb-0">Containers</h3>
          <div class="col-sm-12 col-md-6 col-xl-4 text-end">
            <button class="col-sm-12 col-md-5 col-xl-5 btn btn-ancotrans mb-2" (click)="onEdit()">Create container</button>
          </div>
        </div>
      </div>
    </div>

    <div class="top-spacer"></div>

    <div>
      <form [formGroup]="searchForm" class="sticky-header">
        <div class="row">
          <div class="col-md-4 col-sm-6 col-xl-2 d-flex">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Search container number</mat-label>
              <input
                autocomplete="off"
                matInput
                formControlName="containerNumber"
                (ngModelChange)="resetOffset()"
              />
              <mat-icon matSuffix>search</mat-icon>
            </mat-form-field>
          </div>
          <div class="col-md-4 col-sm-6 col-xl-2 d-flex">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Search type</mat-label>
              <input
                autocomplete="off"
                matInput
                formControlName="type"
                (ngModelChange)="resetOffset()"
              />
              <mat-icon matSuffix>search</mat-icon>
            </mat-form-field>
          </div>
          <div class="col-md-4 col-sm-6 col-xl-2 d-flex">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Search owner code</mat-label>
              <input
                autocomplete="off"
                matInput
                formControlName="ownerCode"
                (ngModelChange)="resetOffset()"
              />
              <mat-icon matSuffix>search</mat-icon>
            </mat-form-field>
          </div>
          <div class="col-md-4 col-sm-6 col-xl-3 d-flex">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Search owner company</mat-label>
              <input
                autocomplete="off"
                matInput
                formControlName="ownerCompany"
                (ngModelChange)="resetOffset()"
              />
              <mat-icon matSuffix>search</mat-icon>
            </mat-form-field>
          </div>

          <div class="col-md-3 col-sm-5 col-xl-3 d-flex">
            <mat-form-field class="w-100" appearance="outline">
              <mat-label>Select country code</mat-label>
              <mat-select
                (change)="resetOffset()"
                formControlName="countryCode"
              >
                <mat-option
                  *ngFor="let country of countries"
                  [value]="country.code"
                >
                  {{ country.official_name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-1 col-sm-12">
        </div>
        </div>
        <div class="row p-2">
          <div class="col-12">
            <div class="row">
              <button
                (click)="getContainers()"
                class="col-sm-12 col-md-2 col-xl-1 btn btn-ancotrans mb-2"
              >
                Search
              </button>
              <button
                (click)="clearForm()"
                class="col-sm-12 col-md-2 col-xl-1 btn btn-ancotrans mb-2"
              >
                Clear
              </button>
            </div>
          </div>
        </div>
      </form>

      <table class="table table-striped">
        <thead>
          <tr>
            <th
              scope="col"
              class="pointer"
              (click)="sortContainers('containerNumber')"
            >
              Container number
              <i
                *ngIf="sortValues.containerNumber"
                class="fa"
                [ngClass]="{
                  'fa-arrow-down': sortValues.containerNumber == 'asc',
                  'fa-arrow-up': sortValues.containerNumber == 'desc'
                }"
              ></i>
            </th>
            <th scope="col" class="pointer" (click)="sortContainers('type')">
              Type
              <i
                *ngIf="sortValues.type"
                class="fa"
                [ngClass]="{
                  'fa-arrow-down': sortValues.type == 'asc',
                  'fa-arrow-up': sortValues.type == 'desc'
                }"
              ></i>
            </th>
            <th scope="col">
              Length
              <!--<i *ngIf="sortValues.houseNumber" class="fa" [ngClass]="{'fa-arrow-down': sortValues.containerLength == 'asc', 'fa-arrow-up': sortValues.containerLength == 'desc'}"></i>-->
            </th>
            <th scope="col">
              Height
              <!--<i *ngIf="sortValues.name" class="fa" [ngClass]="{'fa-arrow-down': sortValues.containerHeight == 'asc', 'fa-arrow-up': sortValues.containerHeight == 'desc'}"></i>-->
            </th>
            <th scope="col">
              Tare weight kg
              <!--<i *ngIf="sortValues.zip" class="fa" [ngClass]="{'fa-arrow-down': sortValues.tareWeightKg == 'asc', 'fa-arrow-up': sortValues.tareWeightKg == 'desc'}"></i>-->
            </th>
            <th scope="col">
              Max payload
              <!--<i *ngIf="sortValues.city" class="fa" [ngClass]="{'fa-arrow-down': sortValues.maxPayload == 'asc', 'fa-arrow-up': sortValues.maxPayload == 'desc'}"></i>-->
            </th>
            <th scope="col">
              Data score
              <!--<i *ngIf="sortValues.country" class="fa" [ngClass]="{'fa-arrow-down': sortValues.dataScore == 'asc', 'fa-arrow-up': sortValues.dataScore == 'desc'}"></i>-->
            </th>
            <th
              scope="col"
              class="pointer"
              (click)="sortContainers('ownerCode')"
            >
              Owner code
              <i
                *ngIf="sortValues.ownerCode"
                class="fa"
                [ngClass]="{
                  'fa-arrow-down': sortValues.ownerCode == 'asc',
                  'fa-arrow-up': sortValues.ownerCode == 'desc'
                }"
              ></i>
            </th>
            <th
              scope="col"
              class="pointer"
              (click)="sortContainers('ownerCompany')"
            >
              Owner company
              <i
                *ngIf="sortValues.ownerCompany"
                class="fa"
                [ngClass]="{
                  'fa-arrow-down': sortValues.ownerCompany == 'asc',
                  'fa-arrow-up': sortValues.ownerCompany == 'desc'
                }"
              ></i>
            </th>
            <th
              scope="col"
              class="pointer"
              (click)="sortContainers('countryCode')"
            >
              Country code
              <i
                *ngIf="sortValues.countryCode"
                class="fa"
                [ngClass]="{
                  'fa-arrow-down': sortValues.countryCode == 'asc',
                  'fa-arrow-up': sortValues.countryCode == 'desc'
                }"
              ></i>
            </th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <div *ngIf="loading" class="d-table-row table-spiner">
            <ngx-spinner [fullScreen]="false"> </ngx-spinner>
          </div>
          <ng-container *ngIf="!loading && containerContent">
            <tr scope="row" *ngFor="let container of containerContent.content">
              <td>{{ container.container_number }}</td>
              <td>{{ container.type }}</td>
              <td>{{ container.length_feet }}</td>
              <td>{{ container.height_feet }}</td>
              <td>{{ container.tare_weight_kg }}</td>
              <td>{{ container.max_payload_kg }}</td>
              <td>{{ container.data_score }}</td>
              <td>{{ container.owner?.code }}</td>
              <td>{{ container.owner?.company }}</td>
              <td>{{ container.owner?.country?.code }}</td>
              <td align="right">
                <button
                  *ngIf="container.container_number"
                  class="btn btn-ancotrans"
                  (click)="onEdit(container.container_number)"
                >
                  <i class="fa fa-edit"></i>
                </button>
                <button
                  class="btn btn-danger"
                  (click)="onDelete(container.container_number)"
                >
                  <i class="fa fa-remove"></i>
                </button>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>

      <!--
  <paging [offset]="offset"
          [limit]="limit"
          [itemsLength]="totalCount"
          (pageChanged)="pageChanged($event)"></paging>
  -->
    </div>
  </div>
</div>
