import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AgmCoreModule } from '@agm/core';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AmplifyAuthenticatorModule } from '@aws-amplify/ui-angular';
import { BsModalService, ModalModule } from 'ngx-bootstrap/modal';
import { NgxEditorModule } from 'ngx-editor';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthComponent } from './components/auth/auth.component';
import { ContainerComponent } from './components/container/container.component';
import { ContainerModalComponent } from './components/container/container-modal/container-modal.component';
import { CreateLocationComponent } from './components/location/create-location/create-location.component';
import { EditLocationComponent } from './components/location/edit-location/edit-location.component';
import { LocationComponent } from './components/location/location.component';
import { PagingDirective } from './directives/paging/paging.directive';
import { HttpInterceptorProviders } from './interceptors/interceptor-provider.interceptor';
import { AuthManager } from './managers/auth.manager';
import { MaterialModule } from './material-module';
import { CountryService } from './services/country.service';
import { DateService } from './services/date.service';
import { LanguageService } from './services/language.service';
import { UtilsService } from './services/utils.service';
import { AvatarComponent } from './shared/avatar/avatar.component';
import { ModalWindowComponent } from './shared/modal-window/modal-window.component';
import { InsufficientPermissionsComponent } from './shared/insufficient-permissions/insufficient-permissions.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { UserManagementComponent } from './components/user-management/user-management.component';
import { UserDetailsComponent } from './components/user-management/user-details/user-details.component';
import { AddUserComponent } from './components/user-management/add-user/add-user.component';
import { MatDividerModule } from '@angular/material/divider';
import { ContainerDeleteModalComponent } from './components/container/container-delete-modal/container-delete-modal.component';
import { DeleteLocationModalComponent } from './components/location/delete-location-modal/delete-location-modal.component';
import { EdiFactComponent } from './components/edi-fact/edi-fact.component';
import { IdentityServiceComponent } from './components/identity-service/identity-service.component';

@NgModule({
  declarations: [
    AppComponent,
    LocationComponent,
    EditLocationComponent,
    CreateLocationComponent,
    ContainerComponent,
    ContainerModalComponent,

    AvatarComponent,
    PagingDirective,
    ModalWindowComponent,
    AuthComponent,
    InsufficientPermissionsComponent,
    UserManagementComponent,
    UserDetailsComponent,
    AddUserComponent,
    ContainerDeleteModalComponent,
    DeleteLocationModalComponent,
    EdiFactComponent,
    IdentityServiceComponent,
  ],
  imports: [
    MatDividerModule,
    NgxSpinnerModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    ModalModule.forRoot(),
    GoogleMapsModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyA0wE1G0M66Ifynttr4oyEnEdMzn3tI_dE',
      libraries: ['places', 'geometry', 'drawing'],
    }),
    NgxEditorModule,
    MaterialModule,
    MatToolbarModule,
    MatSidenavModule,
    MatNativeDateModule,
    AmplifyAuthenticatorModule,
    MatListModule,
    MatButtonModule,
    MatIconModule,
  ],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    UtilsService,
    CountryService,
    LanguageService,
    DateService,
    BsModalService,
    AuthManager,
    HttpInterceptorProviders,
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
