import { animate, transition, trigger } from '@angular/animations';
import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { BsModalService } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs/internal/Subject';
import { LocationValidationStatus } from 'src/app/enums/location-validation-status.enum';
import { LocationService } from 'src/app/services/location.service';
import { AuditlogInterface } from '../../models/auditlog.interface';
import { CountryInterface } from '../../models/country.interface';
import { LocationTypeInterface } from '../../models/location-type.interface';
import {
  LocationInterface,
  SuggestedLocationDataChange,
} from '../../models/location.interface';
import { PaginationInterface } from '../../models/pagination.interface';
import { DateService } from '../../services/date.service';
import { CreateLocationComponent } from './create-location/create-location.component';
import { EditLocationComponent } from './edit-location/edit-location.component';
import { DeleteLocationModalComponent } from './delete-location-modal/delete-location-modal.component';
declare var $: any;

@Component({
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss'],
  animations: [
    trigger('openClose', [
      transition('open => closed', [animate('0.5s')]),
      transition('closed => open', [animate('0.5s')]),
    ]),
  ],
})
export class LocationComponent implements OnDestroy {
  @ViewChild('pickerFrom') pickerFrom: any;
  @ViewChild('pickerTo') pickerTo: any;
  offset: number = 0;
  limit: number = 10;
  totalCount: number = 0;
  loading: boolean = true;
  locationContent: PaginationInterface<LocationInterface[]> | undefined;
  expandedRowIndex: string = '';
  isOpen = true;
  validationStatuses = LocationValidationStatus;
  suggestedDataChange: SuggestedLocationDataChange;
  selectedLocation: LocationInterface | undefined;
  tabIndexSelected = 0;
  panelOpenState: boolean = false;
  forceName = false;
  isCandidate = false;

  private expandedRowClosed = new Subject();
  private destroy$ = new Subject();

  columnsToDisplay = [
    'Type',
    'Address',
    'House number',
    'Name',
    'Zip',
    'City',
    'Country',
    'Usage count',
    'Created at',
    'Last used',
  ];

  get isCandidateToDelete(): string {
    return this.selectedLocation?.extras.is_candidate
      ? 'Delete candidate location'
      : 'Delete location';
  }

  toggleRow(id: string): void {
    if (this.tabIndexSelected == 1) {
      this.locationService.getSuggestedLocationData(id).subscribe((data) => {
        this.suggestedDataChange = data;
      });

      this.expandedRowIndex = this.expandedRowIndex === id ? '' : id;
      this.isOpen = !this.isOpen;
      if (this.expandedRowIndex === '') {
        this.expandedRowClosed.next();
      }
    }
  }

  isDiffrence(location: any, suggestedData: any): boolean {
    return location !== suggestedData;
  }

  trackById(index: number, item: LocationInterface): string {
    return item.extras.geodata_id;
  }

  countries: CountryInterface[] = [
    {
      name: 'All',
      code: '',
      official_name: 'All countries',
    },
  ];

  sortValues: any = {
    type: undefined,
    address: 'asc',
    houseNumber: undefined,
    name: undefined,
    zip: undefined,
    city: undefined,
    country: undefined,
    usageCount: undefined,
    score: undefined,
    createdAt: undefined,
    lastUsedAt: undefined,
    lastModifiedAt: undefined,
  };

  locationTypes: LocationTypeInterface[] = [
    {
      type: 'ALL',
      name: 'All types',
    },
  ];

  searchTypes: { name: string; value: string }[] = [
    {
      name: 'All saved locations',
      value: 'all',
    },
    {
      name: 'Automatic saved locations',
      value: 'automatic',
    },
    {
      name: 'Manual saved locations',
      value: 'manual',
    },
  ];

  searchForm: FormGroup = new FormGroup({
    resultType: new FormControl('', Validators.required),
    searchName: new FormControl(''),
    searchStreet: new FormControl(''),
    searchZip: new FormControl(''),
    searchCity: new FormControl(''),
    searchCountryCode: new FormControl(''),
    searchHouseNumber: new FormControl(''),
    //searchCountry: new FormControl(''),
    searchAddressType: new FormControl(''),
    searchStartDate: new FormControl(''),
    searchEndDate: new FormControl(''),
  });

  constructor(
    private http: HttpClient,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private dateService: DateService,
    private modal: BsModalService,
    private locationService: LocationService
  ) {
    this.searchForm.controls['resultType'].patchValue(
      this.searchTypes[0].value
    );
    this.searchForm.controls['searchAddressType'].patchValue(
      this.locationTypes[0].type
    );
    this.searchForm.controls['searchCountryCode'].patchValue(
      this.countries[0].code
    );
    this.getCountries();
  }

  getCountries() {
    this.toggleSpinner(true);

    this.locationService.getCountries().subscribe(
      (data) => {
        let countries = data as CountryInterface[];
        this.countries = this.countries.concat(countries);

        this.getLocationTypes();
      },
      () => {
        this.toggleSpinner(false);
      }
    );
  }

  clearForm() {
    this.searchForm.controls['resultType'].patchValue(
      this.searchTypes[0].value
    );
    this.searchForm.controls['searchName'].patchValue('');
    this.searchForm.controls['searchStreet'].patchValue('');
    this.searchForm.controls['searchZip'].patchValue('');
    this.searchForm.controls['searchCity'].patchValue('');
    this.searchForm.controls['searchCountryCode'].patchValue(
      this.countries[0].code
    );
    this.searchForm.controls['searchHouseNumber'].patchValue('');
    // this.searchForm.controls['searchCountry'].patchValue('');
    this.searchForm.controls['searchAddressType'].patchValue(
      this.locationTypes[0].type
    );
    this.searchForm.controls['searchStartDate'].patchValue('');
    this.searchForm.controls['searchEndDate'].patchValue('');
    this.forceName = false;
  }

  getLocationTypes() {
    this.locationService.getLocationTypes().subscribe(
      (data) => {
        let locationTypes = data as LocationTypeInterface[];
        this.locationTypes = this.locationTypes.concat(locationTypes);
        this.getLocations();
      },
      (data) => {
        console.log('ERROR', data);
        this.toggleSpinner(false);
      }
    );
  }

  getLocations() {
    this.toggleSpinner(true);

    this.locationService.getLocations(this.getQueryString()).subscribe(
      (data) => {
        this.locationContent = data as PaginationInterface<LocationInterface[]>;

        this.totalCount = this.locationContent.total_count;

        this.toggleSpinner(false);
      },
      () => {
        this.toggleSpinner(false);
      }
    );
  }

  tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    this.isCandidate = false;
    this.getLocations();
  }

  private getQueryString(): string {
    let qs = '?';
    let searchType = this.searchForm.get('resultType')?.value;
    // search type is required
    qs += 'type=' + searchType;

    // optional
    let name = this.searchForm.get('searchName')?.value;
    if (name) {
      qs += '&name=' + name;
    }

    // optional
    let street = this.searchForm.get('searchStreet')?.value;
    if (street) {
      qs += '&streetName=' + street;
    }

    // optional
    let houseNumber = this.searchForm.get('searchHouseNumber')?.value;
    if (houseNumber) {
      qs += '&HouseNumber=' + houseNumber;
    }

    // optional
    let zip = this.searchForm.get('searchZip')?.value;
    if (zip) {
      qs += '&zip=' + zip;
    }

    // optional
    let city = this.searchForm.get('searchCity')?.value;
    if (city) {
      qs += '&city=' + city;
    }

    // optional
    let countryCode = this.searchForm.get('searchCountryCode')?.value;
    if (countryCode) {
      qs += '&countryCode=' + countryCode;
    }

    if (this.forceName) {
      qs += '&onlyForceName=' + this.forceName;
    }

    if (this.isCandidate) {
      qs += '&onlyCandidateAddresses=' + this.isCandidate;
    } else {
      qs += '&onlyCandidateAddresses=' + false;
    }

    // optional
    /*
    let country = this.searchForm.get('searchCountry')?.value;
    if (country) {
      qs += '&country=' + country;
    }
    */

    // optional
    let addressType = this.searchForm.get('searchAddressType')?.value;
    if (addressType != 'ALL') {
      qs += '&addressType=' + addressType;
    }

    // optional
    let fromCreated = this.searchForm.get('searchStartDate')?.value;
    if (fromCreated) {
      qs +=
        '&fromCreatedOn=' +
        this.dateService.getDateFormatWithoutTime(fromCreated);
    }

    // optional
    let toCreated = this.searchForm.get('searchEndDate')?.value;
    if (toCreated) {
      qs +=
        '&toCreatedOn=' + this.dateService.getDateFormatWithoutTime(toCreated);
    }

    if (this.tabIndexSelected === 1) {
      qs += '&onlyValidationRequired=' + true;
    } else {
      qs += '&onlyValidationRequired=' + false;
    }

    qs += this.getSort();

    qs += '&offset=' + this.offset + '&limit=' + this.limit;

    return qs;
  }

  private getSort() {
    if (this.sortValues.type != undefined) {
      return '&sortName=addressType&sortDirection=' + this.sortValues.type;
    } else if (this.sortValues.address != undefined) {
      return '&sortName=address&sortDirection=' + this.sortValues.address;
    } else if (this.sortValues.houseNumber != undefined) {
      return (
        '&sortName=HouseNumber&sortDirection=' + this.sortValues.houseNumber
      );
    } else if (this.sortValues.name != undefined) {
      return '&sortName=name&sortDirection=' + this.sortValues.name;
    } else if (this.sortValues.zip != undefined) {
      return '&sortName=zip&sortDirection=' + this.sortValues.zip;
    } else if (this.sortValues.city != undefined) {
      return '&sortName=city&sortDirection=' + this.sortValues.city;
    } else if (this.sortValues.country != undefined) {
      return '&sortName=countryCode&sortDirection=' + this.sortValues.country;
    } else if (this.sortValues.createdAt != undefined) {
      /*
    else if (this.sortValues.usageCount != undefined) {
      return '&sortName=&sortDirection=' + this.sortValues.usageCount;
    }
    else if (this.sortValues.score != undefined) {
      return '&sortName=&sortDirection=' + this.sortValues.score;
    }
    */
      return '&sortName=createdOn&sortDirection=' + this.sortValues.createdAt;
    } else if (this.sortValues.lastModifiedAt != undefined) {
      /*
    else if (this.sortValues.lastUsedAt != undefined) {
      return '&sortName=&sortDirection=' + this.sortValues.lastUsedAt;
    }*/
      return (
        '&sortName=modifiedOn&sortDirection=' + this.sortValues.lastModifiedAt
      );
    }
    return '';
  }

  toggleSpinner(show: boolean) {
    this.loading = show;
    if (show) {
      this.spinner.show();
    } else {
      this.spinner.hide();
    }
  }

  pageChanged(event: Event) {
    let response = event as unknown as { offset: number };
    if (this.offset != response.offset) {
      this.offset = response.offset;
      this.getLocations();
    }
  }

  resetOffset() {
    if (this.locationContent) {
      this.offset = 0;
    }
  }

  sortLocations(name: string) {
    let direction = this.sortValues[name];
    direction = direction == 'asc' ? 'desc' : 'asc';
    this.sortValues = {
      type: undefined,
      address: undefined,
      name: undefined,
      zip: undefined,
      city: undefined,
      country: undefined,
      usageCount: undefined,
      score: undefined,
      createdAt: undefined,
      lastUsedAt: undefined,
      lastModifiedAt: undefined,
    };
    this.sortValues[name] = direction;
    this.getLocations();
  }

  downloadAuditLog() {
    this.toggleSpinner(true);

    this.locationService.downloadAuditLogs().subscribe(
      (data) => {
        let auditlogs = data as AuditlogInterface[];
        let csvContent = this.convertAuditlogToCsv(auditlogs);
        this.startDownloadOfAuditlog(csvContent);
        this.toggleSpinner(false);
      },
      () => {
        this.toggleSpinner(false);
        this.toastr.error('Could not fetch auditlog', 'Failure');
      }
    );
  }

  convertAuditlogToCsv(auditlogs: AuditlogInterface[]): string[] {
    let csvStrings: any[] = [];
    const headerValues = Object.keys(auditlogs[0]).map((key) => [key]);
    const commaJoinedHeaderValues = headerValues.join(', ').replace(/_+/g, ' ');

    csvStrings.push(commaJoinedHeaderValues);

    for (let auditLog of auditlogs) {
      let csv = '';
      for (const [key, value] of Object.entries(auditLog)) {
        csv += `"${value}",`;
      }
      csvStrings.push(csv);
    }
    return csvStrings;
  }

  startDownloadOfAuditlog(csvContent: string[]) {
    const csvArray = csvContent.join('\r\n');

    const a = document.createElement('a');
    const blob = new Blob([csvArray], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);

    a.href = url;
    a.download = 'auditlogs.csv';
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }

  validateLocationData(locationId: string): void {
    this.locationService.locationValidate(locationId, null).subscribe(
      (data) => {
        this.toggleSpinner(false);
        this.toastr.success('Location has been validated', 'Success');
        this.getLocations();
        this.isOpen = false;
        this.expandedRowIndex = '';
      },
      (err) => {
        this.toggleSpinner(false);
        this.toastr.error('Location has been not validated', 'Failure');
      }
    );
  }

  approveSuggestedLocationData(locationId: string): void {
    this.locationService.locationApproveSuggestion(locationId, null).subscribe(
      (data) => {
        this.toggleSpinner(false);
        this.toastr.success(
          'Location suggested data has been approved',
          'Success'
        );
        this.getLocations();
      },
      (err) => {
        this.toggleSpinner(false);
        this.toastr.error(
          'Location suggested data has been not approved',
          'Failure'
        );
      }
    );
  }

  rejectSuggestedLocationData(locationId: string): void {
    this.locationService.locationRejectSuggestion(locationId, null).subscribe(
      (data) => {
        this.toggleSpinner(false);
        this.toastr.success(
          'Location suggested data has been rejected',
          'Success'
        );
        this.getLocations();
      },
      (err) => {
        this.toggleSpinner(false);
        this.toastr.error(
          'Location suggested data has been not rejected',
          'Failure'
        );
      }
    );
  }

  onEdit(location: LocationInterface): void {
    const modalRef = this.modal.show(EditLocationComponent, {
      class: 'modal-lg',
      initialState: {
        title: 'Edit location',
        data: location,
        locationTypes: this.locationTypes,
      },
      backdrop: 'static',
    });

    modalRef.content?.closed.subscribe(() => {
      modalRef.hide();
      this.getLocations();
    });
  }

  onDelete(location: LocationInterface): void {
    const modalRef = this.modal.show(DeleteLocationModalComponent, {
      class: 'modal-md',
      initialState: {
        selectedLocation: location,
      },
      backdrop: 'static',
    });

    modalRef.content?.closed.subscribe(() => {
      modalRef.hide();
      this.getLocations();
    });
  }

  onCreate(): void {
    this.modal.show(CreateLocationComponent, {
      class: 'modal-lg',
      initialState: { locationTypes: this.locationTypes },
    });
  }

  ngOnDestroy(): void {
    this.destroy$.complete();
  }
}
