import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-modal-window',
  templateUrl: './modal-window.component.html',
  styleUrls: ['./modal-window.component.scss']
})
export class ModalWindowComponent implements OnInit, OnDestroy {
  @Input() title: string = '';
  @Input() data: any;
  @Input() showContinueButton: boolean = false;

  public onContinueAction: Subject<boolean>;
  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit(): void {
    this.onContinueAction = new Subject();
  }

  continue(): void {
    this.bsModalRef.hide();
    this.onContinueAction.next(true);
  }

  ngOnDestroy(): void {
    this.onContinueAction?.unsubscribe();
  }
}
